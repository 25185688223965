import NordLogo from '@nord/ui/src/components/NordLogo'
import Paginator from '@nord/ui/src/components/Paginator'
import SaxoLogo from '@nord/ui/src/components/SaxoLogo'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import useFlow from '../../hooks/useFlow'
import { selectCurrentPortfolio } from '../../store/current/portfolioId'
import { selectCurrentUser } from '../../store/current/user'

import accountTypeTextMap from './accountTypeTextMap'
import ChangeAccountTypeButton from './ChangeAccountTypeButton'
import ContentTitle from './ContentTitle'

const IntroPage = (otherProps: any) => {
  const flow = useFlow()
  // @ts-expect-error TS(2339) FIXME: Property 'fullRiskScoreTitle' does not exist on ty... Remove this comment to see the full error message
  const { fullRiskScoreTitle } = useObjectSelector(selectCurrentPortfolio)
  // @ts-expect-error TS(2339) FIXME: Property 'readyForSaxoAt' does not exist on type '... Remove this comment to see the full error message
  const { readyForSaxoAt } = useObjectSelector(selectCurrentUser)

  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const accountTypeTexts = accountTypeTextMap[flow]

  return (
    <Paginator.Form.Page {...otherProps}>
      <ContentTitle title={`Oprettelse af ${accountTypeTexts.accountText} i Saxo Bank`} />
      <Row>
        <Col>
          <p className="text-center h5">
            Risikoprofil:{' '}
            <span className="text-primary font-weight-bold text-underline">
              {fullRiskScoreTitle}
            </span>
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <p className="text-center">
            Før du kan investere, skal du have en konto hos Saxo Bank. På de næste sider, skal du
            svare på spørgsmål om dig og din økonomi. Det tager ca. 5 min. Du accepterer at vi
            videregiver dine oplysninger til Saxo Bank, samt at de må behandle dem.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div className="logo-wrapper">
            <div className="logo-inner">
              <NordLogo />
            </div>
            <div className="logo-inner d-none d-sm-block text-center">
              <div>i samarbejde med</div>
            </div>
            <div className="logo-inner">
              <SaxoLogo height={70} />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <Paginator.Form.Navigation.NextButton>
            Start oprettelse af {accountTypeTexts.accountText}
          </Paginator.Form.Navigation.NextButton>
        </Col>
      </Row>
      {!readyForSaxoAt && (
        <Row>
          <Col className="text-center mt-4">
            <ChangeAccountTypeButton />
          </Col>
        </Row>
      )}
    </Paginator.Form.Page>
  )
}

export default IntroPage
