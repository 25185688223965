import BaffleButton from '@nord/ui/src/components/BaffleButton'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import { PATHS } from '../../../../configuration/constants'
import useFlow from '../../../../hooks/useFlow'
import { selectCurrentPortfolioId } from '../../../../store/current/portfolioId'
import { selectCurrentUser } from '../../../../store/current/user'
import ContentContainer from '../../ContentContainer'
import ContentTitle from '../../ContentTitle'

const FailurePage = () => {
  const flow = useFlow()
  const portfolioId = useSelector(selectCurrentPortfolioId)
  // @ts-expect-error TS(2339) FIXME: Property 'nationality' does not exist on type 'unk... Remove this comment to see the full error message
  const { nationality, country } = useObjectSelector(selectCurrentUser)
  const foreignResident = nationality !== 'DK' || country !== 'DK'

  return (
    <ContentContainer>
      <ContentTitle title="Vi kunne ikke verificere dig..." />
      <p className="text-center">
        {foreignResident ? (
          <>
            Da du har angivet at du enten er udenlandsk statsborger eller bosat i udlandet, kan du
            desværre ikke blive verificeret automatisk.
          </>
        ) : (
          <>Saxo Bank kan desværre ikke verificere dig automatisk.</>
        )}{' '}
        Det betyder, at vi skal bruge dokumentation på din identitet og adresse, for at du manuelt
        kan blive verificeret.
      </p>
      <p className="text-center">
        På næste side vil du blive bedt om at uploade dit kørekort eller pas, samt sundhedskort.
        Herefter er oprettelsen afsluttet, og vi vender tilbage så snart din konto står klar.
      </p>
      <Row>
        <Col className="text-center mt-4">
          <BaffleButton
            data-cy="next-button"
            variant="primary"
            as={Link}
            type={undefined}
            // @ts-expect-error TS(2322) FIXME: Type '{ children: string; "data-cy": string; varia... Remove this comment to see the full error message
            to={PATHS[flow].verification(portfolioId)}
          >
            Fortsæt
          </BaffleButton>
        </Col>
      </Row>
    </ContentContainer>
  )
}

export default FailurePage
