import Form from '@nord/ui/src/components/Form'
import ImageInput from '@nord/ui/src/components/ImageInput'
import LoadingButton from '@nord/ui/src/components/LoadingButton'
import { useFormikContext } from 'formik'
import React, { useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { useUploadFile } from './OnboardingFlow/Shared/upload-hooks'

export interface Props {
  name: string
  fileName: string
  email: string
}

const ImageField = ({ name, fileName, email }: Props) => {
  const { setFieldValue } = useFormikContext()
  const [uploadState, setUploadState] = useState({
    complete: false,
    uploading: false,
    error: false,
  })
  const uploadFile = useUploadFile()

  const handleImageChange = async (newImage: any) => {
    const path = `/${email}/${process.env.REACT_APP_KYC_FOLDER}`

    setUploadState({
      complete: false,
      uploading: true,
      error: false,
    })

    try {
      await uploadFile({ file: newImage, path, fileName })

      const reader = new FileReader()

      reader.onload = () => {
        const imageSrc = reader.result
        setFieldValue(name, imageSrc, true)
      }

      reader.readAsDataURL(newImage)

      setUploadState({
        complete: true,
        uploading: false,
        error: false,
      })
    } catch {
      setUploadState({
        complete: false,
        uploading: false,
        error: true,
      })
    }
  }

  return (
    <>
      <Row>
        <Col>
          {/* @ts-expect-error TS(2322) FIXME: Type '{ name: string; onValueChange: (newImage: an... Remove this comment to see the full error message */}
          <ImageInput name={name} onValueChange={handleImageChange} className="mx-auto" />
        </Col>
      </Row>
      <Row>
        <Col>
          {uploadState.complete ? (
            <div className="text-center mt-4">
              {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; variant: string; "data-c... Remove this comment to see the full error message */}
              <Form.SubmitButton variant="success" data-cy="next-button" filled loadingIcon>
                Godkend billedet
              </Form.SubmitButton>
            </div>
          ) : (
            uploadState.uploading && (
              <div className="text-center mt-4">
                {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; variant: string; loading... Remove this comment to see the full error message */}
                <LoadingButton variant="primary" loading>
                  Uploader...
                </LoadingButton>
              </div>
            )
          )}

          {uploadState.error && (
            <Alert variant="warning" className="text-center mt-4">
              Vi kunne desværre ikke uploade dit billede. Prøv venligst igen. Check evt. at du ikke
              har nogen ad-blockere aktiveret.
              <br />
              Hvis du forsat oplever problemer, så kontakt os gerne via chatten i nederste højre
              hjørne så sidder vi klar til at hjælpe dig!
            </Alert>
          )}
        </Col>
      </Row>
    </>
  )
}

export default ImageField
