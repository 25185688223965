import { deepFreeze } from '../../../utilities/deepIterate'
import environment from '../environment'

const { domains } = environment

const urls = deepFreeze({
  external: {
    calendly: 'https://calendly.com/nord-investments/intro',
    faq: 'https://faq.nord.investments/',
    taxCalculator: 'https://www.beregnaktieskat.dk/',
    facebook: 'https://www.facebook.com/nordinvestments',
    instagram: 'https://www.instagram.com/nordinvestments',
    twitter: 'https://twitter.com/nordinvestments',
    linkedIn: 'https://www.linkedin.com/company/northholders-a-s',
    googleMaps: "https://www.google.dk/maps/dir/''/nord.investments",
  },
  app: {
    frontpage: `${domains.app}/`,
    signIn: `${domains.app}/brugere/logind`,
    referrals: `${domains.app}/konto/henvisning`,
  },
  website: {
    frontpage: `${domains.website}/`,
    about: `${domains.website}/om`,
    products: `${domains.website}/vi-tilbyder`,
    investor: `${domains.website}/investor`,
    careers: `${domains.website}/karriere`,
    returns: `${domains.website}/afkast`,
    pricing: `${domains.website}/priser`,
    etf: `${domains.website}/etf`,
    wealth: `${domains.website}/wealth`,
    webinars: `${domains.website}/webinars`,
    responsibleInvestment: `${domains.website}/ansvarlig-investering`,
    generalTerms: `${domains.website}/dokumenter/forretningsbetingelser`,
    privacyPolicy: `${domains.website}/dokumenter/privatlivspolitik`,
    conflictOfInterestPolicy: `${domains.website}/dokumenter/interessekonfliktpolitik`,
    responsibleInvestmentPolicy: `${domains.website}/dokumenter/ansvarlige-investeringer-politik`,
    salaryPolicy: `${domains.website}/dokumenter/P-NORD-04-03`,
    financialSupervisoryAuthorityNotice: `${domains.website}/dokumenter/finanstilsynets-redegørelse`,
    priceList: `${domains.website}/prisliste`,
    terms: `${domains.website}/vilkaar`,
    policies: `${domains.website}/politikker`,
    financialSupervisoryAuthority: `${domains.website}/finanstilsynet`,
    complaintsGuide: `${domains.website}/klagevejledning`,
  },
  blog: {
    frontpage: `${domains.blog}/`,
  },
  backend: {
    signOut: `${domains.backend}/users/sign_out`,
  },
  onboarding: {
    frontpage: `${domains.onboarding}/`,
    signIn: `${domains.onboarding}/brugere/logind`,
    referred: `${domains.app}/henvisning`,
  },
})

export default urls
