import LoadingButton from '@nord/ui/src/components/LoadingButton'
import React, { useContext } from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowRight from 'react-feather/dist/icons/arrow-right'

import useSignedIn from '../../../../../../../hooks/useSignedIn'
import ResultPageContext from '../../ResultPageContext'

const StartOnboarding = ({ ...otherProps }) => {
  const signedIn = useSignedIn()
  // @ts-expect-error TS(2339) FIXME: Property 'onStartOnboarding' does not exist on typ... Remove this comment to see the full error message
  const { onStartOnboarding, isSavingRiskScore } = useContext(ResultPageContext)

  return (
    <LoadingButton
      // @ts-expect-error TS(2322) FIXME: Type '{ children: string; onClick: any; variant: s... Remove this comment to see the full error message
      onClick={onStartOnboarding}
      variant="success"
      icon={ArrowRight}
      iconPlacement="last"
      loading={isSavingRiskScore}
      disabled={!signedIn}
      filled
      {...otherProps}
    >
      Start din investering nu
    </LoadingButton>
  )
}

export default StartOnboarding
