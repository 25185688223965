import BaffleButton from '@nord/ui/src/components/BaffleButton'
import useApi from '@nord/ui/src/hooks/useApi'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import useWindowEventHandler from '@nord/ui/src/hooks/useWindowEventHandler'
import React, { useState, useCallback } from 'react'
import Alert from 'react-bootstrap/Alert'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import useWaitForStateChange from '../../../../hooks/useWaitForStateChange'
import { selectCurrentPortfolio } from '../../../../store/current/portfolioId'
import ContentContainer from '../../ContentContainer'
import ContentTitle from '../../ContentTitle'
import TimeoutProgressBar from '../../TimeoutProgressBar'

const PenneoSigningPage = () => {
  const [timedOut, setTimedOut] = useState(false)
  const [linkIsClicked, setLinkIsClicked] = useState(false)
  const [userReturnedToWindow, setUserReturnedToWindow] = useState(false)
  // @ts-expect-error TS(2339) FIXME: Property 'penneoSigningUrl' does not exist on type... Remove this comment to see the full error message
  const { penneoSigningUrl, id: portfolioId } = useObjectSelector(selectCurrentPortfolio)
  const startSigningOfDocuments = useApi('/onboarding/:portfolioId/documents/signing', {
    method: 'POST',
    withCredentials: true,
    errorHandling: {
      ignore: {
        client: true,
      },
    },
  })

  const handleTimeout = useCallback(() => setTimedOut(true), [])
  const goalState = userReturnedToWindow
    ? ['documents_signed', 'ready_to_onboard', 'kyc_obtained', 'saxo_verified']
    : false

  useWaitForStateChange(goalState, handleTimeout)

  const handleFocusWindow = useCallback(() => {
    if (!linkIsClicked || userReturnedToWindow) return

    setUserReturnedToWindow(true)
    startSigningOfDocuments(undefined, { pathParams: { portfolioId } })
  }, [linkIsClicked, portfolioId, startSigningOfDocuments, userReturnedToWindow])

  const handleClickLink = () => {
    startSigningOfDocuments(undefined, { pathParams: { portfolioId } })
    setLinkIsClicked(true)
  }

  useWindowEventHandler('focus', handleFocusWindow)

  const renderMessage = () => {
    if (timedOut) {
      return (
        <Alert variant="warning">
          Vi kunne ikke hente dine underskrift fra Penneo. Hvis du allerede har underskrevet, så
          prøv at genindlæs siden. Ellers så følg linket nedenfor for at underskrive dine
          dokumenter.
        </Alert>
      )
    }

    if (linkIsClicked) {
      return (
        <>
          <p>Vi henter din underskrift fra penneo, vent et øjeblik...</p>
          <TimeoutProgressBar />
        </>
      )
    }

    return (
      <>
        <p>
          Du mangler kun at underskrive dine dokumenter! Når du trykker nedenfor, åbner dine
          dokumenter i en ny side, hvor du kan underskrive dem.
        </p>
        <p>
          Herefter skal du lukke dokument vinduet, for at vende tilbage hertil og færdiggøre din
          oprettelse.
        </p>
      </>
    )
  }

  return (
    <ContentContainer>
      <ContentTitle title="Du er der næsten..." />
      <Row>
        <Col className="text-center">{renderMessage()}</Col>
      </Row>

      {(!linkIsClicked || timedOut) && (
        <Row>
          <Col className="text-center mt-4">
            <BaffleButton
              variant="primary"
              type={undefined}
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              href={penneoSigningUrl}
              onClick={handleClickLink}
            >
              Fortsæt til underskrift
            </BaffleButton>
          </Col>
        </Row>
      )}
    </ContentContainer>
  )
}

export default PenneoSigningPage
