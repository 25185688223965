/* eslint-disable no-console */

import flatMap from 'lodash/flatMap'
import isPlainObject from 'lodash/isPlainObject'

const debugEnabled = process.env.REACT_APP_DEBUG === 'true'

const messageTitle = (message: any) => [`%c${message}:`, ';font-weight:bold;']

export const excludeLog = (title: any) => {
  // @ts-expect-error TS(2339) FIXME: Property 'logExclude' does not exist on type 'Wind... Remove this comment to see the full error message
  window.logExclude = window.logExclude || []
  // @ts-expect-error TS(2339) FIXME: Property 'logExclude' does not exist on type 'Wind... Remove this comment to see the full error message
  window.logExclude.push(title)
}

const checkDebug = (title: any, condition = true) => {
  if (!debugEnabled) return false
  if (!condition) return false
  // @ts-expect-error TS(2339) FIXME: Property 'logFilter' does not exist on type 'Windo... Remove this comment to see the full error message
  if (window.logFilter && !window.logFilter.includes(title)) return false
  if (
    // @ts-expect-error TS(2339) FIXME: Property 'logExclude' does not exist on type 'Wind... Remove this comment to see the full error message
    window.logExclude &&
    // @ts-expect-error TS(2339) FIXME: Property 'logFilter' does not exist on type 'Windo... Remove this comment to see the full error message
    (!window.logFilter || window.logFilter.length === 0) &&
    // @ts-expect-error TS(2339) FIXME: Property 'logExclude' does not exist on type 'Wind... Remove this comment to see the full error message
    window.logExclude.includes(title)
  )
    return false

  return true
}

const debugLog = ({ title, grouped, messages }: any = {}) => {
  try {
    const lines = flatMap(messages, (message) => {
      if (isPlainObject(message)) {
        return Object.entries(message).map(([key, value]) => [...messageTitle(key), value])
      }

      return [message]
    })

    lines.forEach((line) => {
      if (grouped) {
        console.debug(...line)
      } else {
        console.debug('DEBUG:', title, line)
      }
    })
  } catch {
    // ignore errors en debugLog
  }
}

export const buildDebugLog = (title: any, options = {}) => {
  // @ts-expect-error TS(2339) FIXME: Property 'defaultMessage' does not exist on type '... Remove this comment to see the full error message
  const { defaultMessage = [], exclude } = options
  if (exclude) excludeLog(title)

  return {
    title,
    grouped: false,
    defaultMessage: [...defaultMessage],
    log(description: any, ...message: any[]) {
      if (!checkDebug(this.title)) return

      this.start(description)
      debugLog({
        title: this.title,
        grouped: this.grouped,
        messages: [...this.defaultMessage, ...message],
      })
      this.end()
    },
    logIf(condition: any, description: any, ...message: any[]) {
      if (!checkDebug(this.title, condition)) return

      this.log(description, ...message)
    },
    start(description: any) {
      if (!checkDebug(this.title)) return

      this.grouped = true
      console.groupCollapsed(title, description)
    },
    end() {
      if (!checkDebug(this.title)) return

      this.grouped = false
      console.groupEnd()
    },
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'globalDebug' does not exist on type 'Win... Remove this comment to see the full error message
window.globalDebug = debugLog

export default debugLog
