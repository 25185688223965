import Paginator from '@nord/ui/src/components/Paginator'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { object, string } from 'yup'

import { selectCurrentUser } from '../../../../store/current/user'
import ImageField from '../../../ImageField'
import ContentTitle from '../../ContentTitle'

import InfoModalLink from './InfoModalLink'

const labels = {
  socialSecurityCard: 'Sundhedskort',
}

const validationSchema = object().shape({
  socialSecurityCard: string().required(),
})

const ProofOfResidencyPage = (otherProps: any) => {
  // @ts-expect-error TS(2339) FIXME: Property 'email' does not exist on type 'unknown'.
  const { email } = useObjectSelector(selectCurrentUser)

  return (
    <Paginator.Form.Page
      validationSchema={validationSchema}
      labelDescriptions={labels}
      {...otherProps}
    >
      <ContentTitle title="Sundhedskort (gult sygesikringsbevis)" />
      <Row>
        <Col>
          <p className="text-center">
            Vi har brug for dit sundhedskort for at bekræfte din adresse. Tag et billede af dit
            sundhedskort og upload her.
          </p>
          <p className="text-center">
            <strong>
              Sørg for at hele sundhedskortet er med på billedet og er tydelig læsbart, samt at
              adressen er din nuværende adresse.
            </strong>
          </p>
        </Col>
      </Row>
      <ImageField name="socialSecurityCard" fileName="ProofOfResidency" email={email} />
      <p className="text-center mt-3">
        <InfoModalLink
          buttonText="Har du ikke et gyldigt sundhedskort?"
          modalTitle="Dokumentation for adresse"
          infoText="Hvis du ikke har et gyldigt sundhedskort, så kan du uploade et billede af en elregning, mobilregning, internetregning eller et kontoudtog med dit navn og din adresse på. Det må højst være 6 måneder gammelt."
        />
      </p>
    </Paginator.Form.Page>
  )
}

export default ProofOfResidencyPage
