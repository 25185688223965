import IconButton from '@nord/ui/src/components/IconButton'
import Text from '@nord/ui/src/components/Text'
import { getConfig } from '@nord/ui/src/configuration'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowRight from 'react-feather/dist/icons/arrow-right'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import useSignedIn from '../../../hooks/useSignedIn'
import ContentTitle from '../ContentTitle'

import Advantages from './Advantages'
import { useReferralContext } from './ReferralContext'
import TermsModal from './TermsModal'

const nordContactInformation = getConfig('contactInformation')

export interface ReferralsContentProps {
  loginPath: string
  onAccept(): void
}

const ReferralsContent = ({ loginPath, onAccept }: ReferralsContentProps) => {
  const isSignedIn = useSignedIn()
  const { referralData, onPageLeave } = useReferralContext()
  const { name, contactInformation, amount, depositLimit } = referralData || {}

  const [showTermsModal, setShowTermsModal] = useState(false)

  const handleShowModal = () => setShowTermsModal(true)
  const handleHideModal = () => setShowTermsModal(false)

  return (
    <>
      <TermsModal show={showTermsModal} onHide={handleHideModal} referralData={referralData} />
      <ContentTitle title={`${name} har inviteret dig til NORD`} />
      <Advantages />
      <Row>
        <Col xs={12} className="mb-3">
          <p className="text-center">
            Du er blevet inviteret af {name} og vil få sat{' '}
            <strong>{numberToCurrency(amount)}</strong> ind på din investeringskonto, når du har
            haft investeret {numberToCurrency(depositLimit)} i mere end en måned.{' '}
            <Button variant="link" className="text-base p-0" onClick={handleShowModal}>
              Læs alle betingelser her.
            </Button>
            {contactInformation && (
              <>
                <br />
                Kontakt {name} på {contactInformation} for at høre om, hvorfor du skal vælge
                NORD.investments. <br /> Du er også altid velkommen til at kontakte NORD.investments
                på{' '}
                <Text as="a" href={nordContactInformation.phoneLink}>
                  {nordContactInformation.phone}
                </Text>{' '}
                eller{' '}
                <Text as="a" href={nordContactInformation.emailLink}>
                  mail
                </Text>
                .
              </>
            )}
          </p>
        </Col>
        <Col xs={12} className="d-flex justify-content-center">
          <IconButton icon={ArrowRight} onClick={onAccept}>
            Næste
          </IconButton>
        </Col>
      </Row>
      {!isSignedIn && (
        <Row>
          <Col className="text-center mt-2">
            <Button
              variant="link"
              className="text-base"
              type={undefined}
              as={Link}
              to={loginPath}
              onClick={onPageLeave}
            >
              Er du eksisterende bruger? Log ind her.
            </Button>
          </Col>
        </Row>
      )}
    </>
  )
}

export default ReferralsContent
