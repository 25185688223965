import BaffleButton from '@nord/ui/src/components/BaffleButton'
import { selectCurrentPortfolioId } from '@nord/ui/src/store/current/portfolioId'
import React from 'react'
import { useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import { PATHS } from '../../../../../../../configuration/constants'
import useFlow from '../../../../../../../hooks/useFlow'
import useSignedIn from '../../../../../../../hooks/useSignedIn'

const RetakeInvestmentPlan = () => {
  const flow = useFlow()
  const signedIn = useSignedIn()
  const portfolioId = useSelector(selectCurrentPortfolioId)

  return (
    <BaffleButton
      variant="link"
      className="text-light"
      disabled={!signedIn}
      as={Link}
      type={undefined}
      // @ts-expect-error TS(2322) FIXME: Type '{ children: string; variant: string; classNa... Remove this comment to see the full error message
      to={PATHS[flow].investmentPlan(portfolioId)}
    >
      Tag investeringsplanen igen
    </BaffleButton>
  )
}

export default RetakeInvestmentPlan
