import Paginator, { usePaginationContext } from '@nord/ui/src/components/Paginator'
import React from 'react'

const Navigation = () => {
  const pagination = usePaginationContext()
  const { absolute } = pagination
  const { first } = absolute || pagination

  return (
    <Paginator.Navigation.Container>
      {!first && (
        // @ts-expect-error TS(2322) FIXME: Type '{ children: string; variant: string; classNa... Remove this comment to see the full error message
        <Paginator.Navigation.PreviousButton variant="link" className="text-light">
          Tilbage
        </Paginator.Navigation.PreviousButton>
      )}
    </Paginator.Navigation.Container>
  )
}

export default Navigation
