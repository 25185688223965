import Form from '@nord/ui/src/components/Form'
import useApi from '@nord/ui/src/hooks/useApi'
import snakeCase from 'lodash/snakeCase'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'

import { useReferralContext } from '../../Referral/ReferralContext'

import FormContent from './FormContent'

const labels = {
  leadSources: 'Hjælp os med at finde ud af, hvor du har hørt om os (frivilligt)',
  referralToken: 'Andet',
}

export interface Props {
  onHide(): void
}

const LeadSourcesModal = ({ onHide, ...otherProps }: Props) => {
  const { onChangeReferralToken } = useReferralContext()
  const [isTokenValid, setIsTokenValid] = useState(false)

  const saveLeadSources = useApi('/users/lead_sources', {
    method: 'POST',
    withCredentials: true,
    errorHandling: {
      ignore: {
        client: true,
      },
    },
  })

  const handleSubmit = async ({
    leadSources,
    referralToken,
  }: {
    leadSources: string[]
    referralToken: string
  }) => {
    const formattedLeadSources = leadSources.map(snakeCase)

    if (!isTokenValid && referralToken !== '') formattedLeadSources.push(referralToken)

    await saveLeadSources({
      user: {
        leadSources: formattedLeadSources,
      },
    })

    if (isTokenValid) onChangeReferralToken(referralToken)

    onHide()
  }

  return (
    <Modal size="lg" onHide={onHide} centered {...otherProps}>
      <Modal.Header closeButton>
        <Modal.Title className="w-100 text-center" data-cy="modal-title">
          Hvor har du hørt om NORD?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          initialValues={{ leadSources: [] }}
          onSubmit={handleSubmit}
          labelDescriptions={labels}
          fields={Object.keys(labels)}
        >
          <FormContent onIsTokenValidChange={setIsTokenValid} />
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default LeadSourcesModal
