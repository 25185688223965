import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import memoize from 'lodash/memoize'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useRouteMatch } from 'react-router-dom'

import { FLOW_PATHS } from '../configuration/constants'
import { selectCurrentPortfolio } from '../store/current/portfolioId'
import { buildDebugLog } from '../utilities/debugLog'

const debugLog = buildDebugLog('useFlow')

const getCurrentFlow = ({ matchFlowPart, portfolioOnboardingFlow }: any) => {
  // Return portfolio backend onboarding flow if present
  if (portfolioOnboardingFlow) {
    debugLog.log(`defaultPortfolio - ${portfolioOnboardingFlow}`, {
      matchFlowPart,
      portfolioOnboardingFlow,
    })

    return portfolioOnboardingFlow
  }

  let matchFlowPath: any
  if (matchFlowPart !== undefined) matchFlowPath = `/${matchFlowPart}`

  let matchFlow
  if (matchFlowPath)
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    matchFlow = Object.keys(FLOW_PATHS).find((flow) => FLOW_PATHS[flow] === matchFlowPath)

  // Use match when not signed in
  debugLog.logIf(!!matchFlow, `match - ${matchFlow}`, {
    result: matchFlow,
    matchFlow,
    matchFlowPart,
    portfolioOnboardingFlow,
  })
  if (matchFlow) return matchFlow

  debugLog.log('none - null', {
    matchFlow,
    matchFlowPart,
    portfolioOnboardingFlow,
  })

  return null
}

const memoizedGetCurrentFlow = memoize(getCurrentFlow, JSON.stringify)

const useFlow = () => {
  const currentPortfolio = useObjectSelector(selectCurrentPortfolio)
  const match = useRouteMatch('/:flow')

  const matchFlowPart = (match && match.params.flow) || undefined
  // @ts-expect-error TS(2339) FIXME: Property 'onboardingFlow' does not exist on type '... Remove this comment to see the full error message
  const { onboardingFlow: portfolioOnboardingFlow } = currentPortfolio

  return memoizedGetCurrentFlow({
    matchFlowPart,
    portfolioOnboardingFlow,
  })
}

export default useFlow
