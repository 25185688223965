import React from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowRight from 'react-feather/dist/icons/arrow-right'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import { SHARED_PATHS, PENSION_PATHS } from '../../../configuration/constants'
import useSignedIn from '../../../hooks/useSignedIn'
import IconButtonLink from '../../IconButtonLink'
import ContentContainer from '../ContentContainer'
import ContentTitle from '../ContentTitle'

const StartPage = () => {
  const signedIn = useSignedIn()

  return (
    <ContentContainer>
      <ContentTitle title="Få en investeringsplan til din pension" />
      <Row>
        <Col xs={12}>
          <p className="text-center">
            Om under 2 minutter har du en personlig investeringsplan til din pensionsopsparing. Når
            du er færdig, har du muligheden for at oprette en konto, hvad end du vil starte en ny
            pension eller flytte en eksisterende til NORD.investments og Saxo Bank
          </p>
        </Col>
      </Row>

      <Row>
        <Col xs={12} className="text-center">
          <p>
            <IconButtonLink
              variant="success"
              icon={ArrowRight}
              to={PENSION_PATHS.investmentPlan('ny')}
              iconPlacement="last"
              data-cy="next-button"
              filled
            >
              Kom i gang med pension
            </IconButtonLink>
          </p>
          {!signedIn && (
            <p>
              <Button
                variant="link"
                className="text-base"
                type={undefined}
                as={Link}
                to={SHARED_PATHS.userSignIn()}
              >
                Er du eksisterende bruger? Log ind her
              </Button>
            </p>
          )}
        </Col>
      </Row>
    </ContentContainer>
  )
}

export default StartPage
