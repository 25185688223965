import { getConfig } from '@nord/ui/src/configuration'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { selectCurrentPortfolio } from '@nord/ui/src/store/current/portfolioId'
import { numberToPercent } from '@nord/ui/src/utilities/numberFormatter'
import React, { useContext } from 'react'

import ResultPageContext from '../../ResultPageContext'

const esgStandardFeeDifference =
  getConfig('numbers.totalFees.freeAssets.esg.base') -
  getConfig('numbers.totalFees.freeAssets.standard.base')

const RiskScoreDiffers = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'esg' does not exist on type 'unknown'.
  const { esg, riskScore, stocksOnly } = useContext(ResultPageContext)

  // @ts-expect-error TS(2339) FIXME: Property 'advisedEsg' does not exist on type 'unkn... Remove this comment to see the full error message
  const { advisedEsg, advisedRiskScore } = useObjectSelector(selectCurrentPortfolio)

  const esgDiffers = advisedEsg !== esg

  return (
    <>
      {!esgDiffers && (
        <>
          <p>
            Vær opmærksom på, at du har valgt en anden risikoprofil end den, som vi anbefaler. Hvis
            du ønsker at acceptere skal du være opmærksom på, at du kan blive eksponeret for en
            større eller mindre risiko end det som dine forhold ligger op til.
          </p>
          <p>
            Er du sikker på, at du vil ændre risikoprofil fra {advisedRiskScore} til{' '}
            {stocksOnly ? '100 % aktier' : riskScore} og dermed gå i mod vores anbefaling?
          </p>
        </>
      )}
      {esgDiffers && (
        <p>
          Vær opmærksom på, at du har valgt en anden risikoprofil end den, som vi anbefaler. Hvis du
          ønsker at fortsætte med dit valg af risikoprofil, skal vi gøre dig opmærksom på, at du kan
          blive eksponeret for en større eller mindre risiko end det som dine forhold ligger op til.
        </p>
      )}
      {esgDiffers && advisedEsg === true && (
        <>
          <p>
            Du har desuden fravalgt NORDs ansvarlige portefølje, som vi på baggrund af dine
            besvarelser i investeringsplanen anbefaler til dig.
          </p>
          <p>
            Administrationsomkostningerne til ETF’erne og omkostningerne til NORD er højere ved
            investering i den ansvarlige portefølje sammenlignet med investering i NORDs
            standardportefølje. De højere omkostninger ved investering i den ansvarlige portefølje
            svarer til {numberToPercent(esgStandardFeeDifference)} årligt af din porteføljes værdi.
          </p>
          <p className="font-weight-bold">
            Jeg ønsker at ændre min risikoprofil fra {advisedRiskScore} til{' '}
            {stocksOnly ? '100 % aktier' : riskScore} og bekræfter, at jeg - trods mine præferencer
            i forhold til bæredygtig investering og NORDs anbefaling til mig - ikke ønsker min
            portefølje investeret i NORDs ansvarlige portefølje, idet lave omkostninger ved
            investering er vigtigere for mig end at min portefølje investeres bæredygtigt.
          </p>
        </>
      )}
      {esgDiffers && advisedEsg === false && (
        <>
          <p>
            På baggrund af dine besvarelser i investeringsplanen, anbefales du desuden NORDs
            standard portefølje, og ved at vælge den ansvarlige portefølje følger du således ikke
            NORDs anbefaling til dig. Er baggrunden for dit valg af den ansvarlige portefølje, at du
            er interesseret i bæredygtig investering, opfordres du til at gennemgå
            investeringsplanen på ny, så vi kan rådgive dig om dine muligheder.
          </p>
          <p className="font-weight-bold">
            Jeg ønsker at ændre min risikoprofil fra {advisedRiskScore} til{' '}
            {stocksOnly ? '100 % aktier' : riskScore} og bekræfter, at jeg - trods mine præferencer
            i forhold til bæredygtig investering og NORDs anbefaling til mig - ønsker min portefølje
            investeret i NORDs ansvarlige portefølje.
          </p>
        </>
      )}
    </>
  )
}

export default RiskScoreDiffers
