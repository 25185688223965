import React from 'react'

export interface Props {
  children: React.ReactNode
}

const CardTitle = ({ children }: Props) => (
  <h4 className="card-title text-center font-size-xl">{children}</h4>
)

export default CardTitle
