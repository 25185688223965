import Transition from '@nord/ui/src/components/Transition'
import { getConfig } from '@nord/ui/src/configuration'
import useInitial from '@nord/ui/src/hooks/useInitial'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import useQuery from '@nord/ui/src/hooks/useQuery'
import { unwrapResult } from '@reduxjs/toolkit'
import classNames from 'classnames'
import Cookies from 'js-cookie'
import isBoolean from 'lodash/isBoolean'
import isNil from 'lodash/isNil'
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useDispatch, useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import { PATHS, INVESTMENT_PLAN_COOKIE_EXPIRY } from '../../../../../configuration/constants'
import useFlow from '../../../../../hooks/useFlow'
import useSignedIn from '../../../../../hooks/useSignedIn'
import { selectInitialLoading } from '../../../../../store/current/loading'
import {
  selectCurrentPortfolio,
  selectCurrentPortfolioId,
} from '../../../../../store/current/portfolioId'
import {
  createCurrentPortfolioOnboarding,
  updateCurrentPortfolioOnboarding,
} from '../../../../../store/current/portfolioId/actions'
import pushToDataLayer, { pushEventToDataLayer } from '../../../../../utilities/pushToDataLayer'
import trackIntercomEvents from '../../../../../utilities/trackIntercomEvents'
import './styles.scss'
import SignUpModal from '../../SignUpModal'

import AllocationSection from './AllocationSection'
import CallToActionButton from './CallToActionButton'
import Disclaimer from './Disclaimer'
import ExpectedReturn from './ExpectedReturn'
import FooterButtons from './FooterButtons'
import HistoricalReturnSection from './HistoricalReturnSection'
import NordWealth from './NordWealth'
import Portfolio from './Portfolio'
import ResponsibleInvestment from './ResponsibleInvestment'
import ResultPageContext from './ResultPageContext'
import RiskScore from './RiskScore'
import SecondaryCallToActionButton from './SecondaryCallToActionButton'
import UpdateAlertModal from './UpdateAlertModal'
import useResultPageTitle from './useResultPageTitle'
import getRiskScoreAdvisedState from './utilities/getRiskScoreAdvisedState'

const nordWealthLimit = 5e6 // 5 millions

const freeAssetsMinimumInvestment = getConfig('numbers.minimumInvestment.freeAssets')

const ResultPage = () => {
  const dispatch = useDispatch()
  const flow = useFlow()
  const signedIn = useSignedIn()
  const history = useHistory()
  const loading = useSelector(selectInitialLoading)
  const portfolioId = useSelector(selectCurrentPortfolioId)
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'unsavedRiskScore' does not exist on type... Remove this comment to see the full error message
    unsavedRiskScore,
    // @ts-expect-error TS(2339) FIXME: Property 'riskScore' does not exist on type 'unkno... Remove this comment to see the full error message
    riskScore: initialRiskScore,
    // @ts-expect-error TS(2339) FIXME: Property 'esg' does not exist on type 'unknown'.
    esg: initialEsg,
    // @ts-expect-error TS(2339) FIXME: Property 'advisedEsg' does not exist on type 'unkn... Remove this comment to see the full error message
    advisedEsg,
    // @ts-expect-error TS(2339) FIXME: Property 'advisedRiskScore' does not exist on type... Remove this comment to see the full error message
    advisedRiskScore,
    // @ts-expect-error TS(2339) FIXME: Property 'expectedInvestment' does not exist on ty... Remove this comment to see the full error message
    expectedInvestment,
    // @ts-expect-error TS(2339) FIXME: Property 'investmentProfileId' does not exist on t... Remove this comment to see the full error message
    investmentProfileId,
  } = useObjectSelector(selectCurrentPortfolio)

  const queryParams = useQuery()
  const { riskScore: initialRiskScoreParam, esg: initialEsgParam } = useInitial(queryParams)

  const [isSavingRiskScore, setIsSavingRiskScore] = useState(false)

  const [initialInvestment, setInitialInvestment] = useState(expectedInvestment || 500000)

  let riskScoreParam = initialRiskScoreParam
  riskScoreParam = riskScoreParam ? parseInt(riskScoreParam, 10) : null
  if (riskScoreParam > 20 || riskScoreParam < 1) riskScoreParam = null
  const [riskScore, setRiskScore] = useState(
    riskScoreParam || unsavedRiskScore || initialRiskScore || advisedRiskScore || 10,
  )

  const isNordWealth = initialInvestment >= nordWealthLimit
  const belowSimpleMinimumInvestmentLimit = initialInvestment < freeAssetsMinimumInvestment
  const stocksOnly = riskScore === 100

  const [isSimple, setIsSimple] = useState(false)
  const simple = isSimple && !stocksOnly

  useEffect(() => {
    setIsSimple(belowSimpleMinimumInvestmentLimit)
  }, [belowSimpleMinimumInvestmentLimit])

  // @ts-expect-error TS(2339) FIXME: Property 'dataLayer' does not exist on type 'Windo... Remove this comment to see the full error message
  if (window.dataLayer)
    pushToDataLayer({
      experimentId: process.env.REACT_APP_GOOGLE_OPTIMIZE_EXPERIMENT_ID,
      variationId: process.env.REACT_APP_GOOGLE_OPTIMIZE_VARIATION_ID,
    })
  const esgParam = initialEsgParam !== undefined ? initialEsgParam === 'true' : undefined
  const initialEsgValue = [esgParam, advisedEsg, initialEsg, false].filter(
    (value) => !isNil(value),
  )[0]
  const [esg, setEsg] = useState(initialEsgValue)

  useEffect(() => {
    if (unsavedRiskScore || initialRiskScore || advisedRiskScore)
      setRiskScore(unsavedRiskScore || initialRiskScore || advisedRiskScore)
  }, [initialRiskScore, advisedRiskScore, unsavedRiskScore])

  useEffect(() => {
    if (initialEsg) setEsg(initialEsg)
  }, [initialEsg])

  const pushTrackingData = useCallback(
    () =>
      pushToDataLayer({
        riskScore,
      }),
    [riskScore],
  )

  const handleSaveRiskScore = useCallback(async () => {
    setIsSavingRiskScore(true)
    const updatedValuesAction = await dispatch(updateCurrentPortfolioOnboarding({ riskScore, esg }))

    trackIntercomEvents()
    pushTrackingData()

    // @ts-expect-error TS(2345) FIXME: Argument of type '(dispatch: any, getState: any) =... Remove this comment to see the full error message
    const updatedValues = unwrapResult(updatedValuesAction)

    pushEventToDataLayer({
      event: 'risk_profile_chosen',
      userID: updatedValues.id,
      expectedInvestment: updatedValues.expectedInvestment,
      flow,
    })

    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    history.push(PATHS[flow].onboarding(portfolioId))
  }, [dispatch, esg, flow, history, portfolioId, pushTrackingData, riskScore])

  const riskScoreAdvisedState = getRiskScoreAdvisedState(riskScore, advisedRiskScore)

  const [showUpdateAlertModal, setShowUpdateAlertModal] = useState(false)

  const esgDiffers = isBoolean(advisedEsg) && advisedEsg !== esg

  // @ts-expect-error TS(7031) FIXME: Binding element 'newRiskScore' implicitly has an '... Remove this comment to see the full error message
  const handleChangeRiskProfile = useCallback(({ riskScore: newRiskScore, esg: newEsg }) => {
    setRiskScore(newRiskScore)
    setEsg(newEsg)
  }, [])

  const handleStartOnboarding = useCallback(
    (event: React.MouseEvent) => {
      if (riskScoreAdvisedState === 'advised' && !esgDiffers) {
        handleSaveRiskScore()

        return
      }

      event.preventDefault()

      setShowUpdateAlertModal(true)
    },
    [esgDiffers, handleSaveRiskScore, riskScoreAdvisedState],
  )

  const { addToast } = useToasts()

  const handleAcceptUpdateRiskScore = useCallback(async () => {
    setIsSavingRiskScore(true)

    await dispatch(updateCurrentPortfolioOnboarding({ riskScore, esg }))

    setIsSavingRiskScore(false)

    addToast(
      <div className="text-reset">
        <h6 className="mb-1">Din risikoprofil er nu opdateret</h6>
        <div>Vi investere dig hurtigst muligt, efter din nye risikoprofil.</div>
      </div>,
      {
        appearance: 'info',
        autoDismiss: true,
      },
    )

    setShowUpdateAlertModal(false)
  }, [addToast, dispatch, esg, riskScore])

  const handleUpdateRiskProfile = useCallback((event: React.MouseEvent) => {
    event.preventDefault()

    setShowUpdateAlertModal(true)
  }, [])

  const [showSignupModal, setShowSignupModal] = useState(true)

  const handleSignUp = async (data: Record<string, unknown>) => {
    const { id: userId, firstName, lastName, email, phone, phonePrefix } = data

    const updatedValuesAction = await dispatch(
      createCurrentPortfolioOnboarding({
        expectedInvestment,
        advisedRiskScore,
        investmentProfileId,
      }),
    )

    // @ts-expect-error TS(2345) FIXME: Argument of type '(dispatch: any, getState: any) =... Remove this comment to see the full error message
    const updatedValues = unwrapResult(updatedValuesAction)

    Cookies.set(
      'investmentPlanResult',
      { values: updatedValues, flow },
      {
        expires: INVESTMENT_PLAN_COOKIE_EXPIRY,
        secure: true,
        sameSite: 'strict',
      },
    )

    pushEventToDataLayer({
      event: 'investment_plan_completed',
      expectedInvestment,
      flow,
      portfolioId: updatedValues.id,
      userId,
      firstName,
      lastName,
      email,
      phoneNumber: `+${phonePrefix}${phone}`,
    })
  }

  const containerStyle = {}

  if (advisedRiskScore && !signedIn && showSignupModal) {
    // @ts-expect-error TS(2339) FIXME: Property 'filter' does not exist on type '{}'.
    containerStyle.filter = 'blur(3px)'
  }

  const title = useResultPageTitle()

  const isLongTitle = title.length >= 30

  const pageContext = useMemo(
    () => ({
      loading,
      riskScore,
      stocksOnly,
      esg,
      pension: false,
      simple,
      expectedInvestment,
      initialInvestment,
      onInitialInvestmentChange: setInitialInvestment,
      onRiskScoreChange: handleChangeRiskProfile,
      onSimpleChange: setIsSimple,
      showSignupModal,
      setShowSignupModal,
      isSavingRiskScore,
      onStartOnboarding: handleStartOnboarding,
      onUpdateRiskProfile: handleUpdateRiskProfile,
      onAcceptUpdateRiskScore: handleAcceptUpdateRiskScore,
    }),
    [
      loading,
      riskScore,
      stocksOnly,
      esg,
      simple,
      expectedInvestment,
      initialInvestment,
      handleChangeRiskProfile,
      showSignupModal,
      isSavingRiskScore,
      handleStartOnboarding,
      handleUpdateRiskProfile,
      handleAcceptUpdateRiskScore,
    ],
  )

  return (
    <ResultPageContext.Provider value={pageContext}>
      <div style={containerStyle} className="pt-5">
        {advisedRiskScore && (
          <SignUpModal
            show={!signedIn && showSignupModal}
            onSignUp={handleSignUp}
            onClose={() => setShowSignupModal(false)}
          />
        )}
        <Row>
          <Col xs={12} xl={{ offset: 1, span: 10 }}>
            <h1 className={classNames('text-center', { h2: isLongTitle })}>{title}</h1>
          </Col>
        </Row>
        {advisedRiskScore && (
          <Row>
            <Col xs={12} xl={{ offset: 2, span: 8 }} className="text-center">
              <p>Investeringsplanen og din risikoprofil er baseret på dine besvarelser.</p>
            </Col>
          </Row>
        )}
        <div className="clearfix mb-lg-5 mb-md-4 mb-sm-3" />
        <Row>
          <RiskScore
            advisedScore={advisedRiskScore}
            // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
            riskScoreAdvisedState={riskScoreAdvisedState}
            callToActionButton={<CallToActionButton />}
            secondaryCallToAction={<SecondaryCallToActionButton />}
          />
          <Col xs={12} lg={8} xl={7}>
            <Transition.Expand
              className={classNames({ 'result-section': isNordWealth })}
              in={isNordWealth}
              key="nordWealth"
              timeout={500}
            >
              <NordWealth />
            </Transition.Expand>
            <ExpectedReturn />
            <Transition.Expand className="result-section" in={esg} key="esg" timeout={500}>
              <ResponsibleInvestment />
            </Transition.Expand>
            <AllocationSection />
            <Portfolio />
            <Transition.Expand
              className="result-section"
              in={!simple}
              key="historicalReturn"
              timeout={500}
            >
              <HistoricalReturnSection />
            </Transition.Expand>
            <FooterButtons />
          </Col>
        </Row>
        <Disclaimer />
        <UpdateAlertModal
          // @ts-expect-error TS(2322) FIXME: Type '{ show: boolean; onHide: () => void; onRiskS... Remove this comment to see the full error message
          show={showUpdateAlertModal}
          onHide={() => setShowUpdateAlertModal(false)}
          onRiskScoreSave={handleSaveRiskScore}
        />
      </div>
    </ResultPageContext.Provider>
  )
}

ResultPage.getPageCount = () => 2

export default ResultPage
