import min from 'lodash/min'

import {
  LOW_EXPECTED_REVENUE_FACTOR,
  HIGH_EXPECTED_REVENUE_FACTOR,
  HIGH_EXPECTED_INVESTMENT_LIMIT,
  EXPECTED_REVENUE_INVESTMENT_LIMIT,
  CLOSING_RATIO,
} from '../configuration/constants'

import { buildDebugLog } from './debugLog'

const debugLog = buildDebugLog('pushToDataLayer')

const pushToDataLayer = (data: any) => {
  debugLog.log(data.event, data)

  // @ts-expect-error TS(2339) FIXME: Property 'dataLayer' does not exist on type 'Windo... Remove this comment to see the full error message
  if (window.dataLayer && window.dataLayer.push) {
    // @ts-expect-error TS(2339) FIXME: Property 'dataLayer' does not exist on type 'Windo... Remove this comment to see the full error message
    window.dataLayer.push(data)
  }
}

const calculateExpectedRevenue = ({ expectedInvestment, event }: any) => {
  const limitedExpectedInvestment = min([EXPECTED_REVENUE_INVESTMENT_LIMIT, expectedInvestment])
  let expectedRevenueFactor = 0
  if (expectedInvestment < HIGH_EXPECTED_INVESTMENT_LIMIT) {
    expectedRevenueFactor = LOW_EXPECTED_REVENUE_FACTOR
  } else {
    expectedRevenueFactor = HIGH_EXPECTED_REVENUE_FACTOR
  }

  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return limitedExpectedInvestment * expectedRevenueFactor * CLOSING_RATIO[event]
}

export const pushEventToDataLayer = ({ event, expectedInvestment, flow, ...data }: any) => {
  const expectedRevenue = calculateExpectedRevenue({
    expectedInvestment,
    event,
  })

  pushToDataLayer({
    event,
    expectedInvestment,
    expectedRevenue,
    accountType: flow,
    ...data,
  })
}

export default pushToDataLayer
