import { getMonth, subYears } from 'date-fns'
import isEmpty from 'lodash/isEmpty'
import React, { useContext } from 'react'

import formatDate from '../../utilities/formatDate'
import { numberToPercent } from '../../utilities/numberFormatter'
import Loader from '../Loader'
import Text from '../Text'

import HistoricalReturnContext from './HistoricalReturnContext'

const depotTypeTranslations = {
  free_assets: 'Frie midler', // eslint-disable-line camelcase
  company: 'Virksomhed',
  pension: 'Pension',
}

const Header = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'chartData' does not exist on type 'unkno... Remove this comment to see the full error message
  const { chartData, depotType, riskScore } = useContext(HistoricalReturnContext)

  const isPension = depotType === 'pension'
  const riskScoreText = isPension ? `P${riskScore}` : riskScore

  const isChartDataEmpty = isEmpty(chartData)

  const yearlyData = !isChartDataEmpty && chartData.filter(({ date }: any) => getMonth(date) === 0)
  const annualizedReturn =
    !isChartDataEmpty &&
    (1 + (yearlyData[yearlyData.length - 1].value - 1)) ** (1 / (yearlyData.length - 1)) - 1

  const lastYear = !isEmpty(yearlyData) && yearlyData[yearlyData.length - 1].date
  const formattedLastYear = lastYear && formatDate(subYears(lastYear, 1), 'yyy')

  return (
    <>
      <Text as="h4" align="center">
        Historisk afkast for {riskScore === 100 && 'ekstra høj'} risikoprofil{' '}
        {/* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
        {riskScore !== 100 && riskScoreText} {depotType && `- ${depotTypeTranslations[depotType]}`}
      </Text>
      <Text as="p" align="center">
        Det gennemsnitlige årlige afkast siden 1999 t.o.m {formattedLastYear || <Loader />} har
        været{' '}
        {annualizedReturn ? numberToPercent(annualizedReturn, { fractionDigits: 2 }) : <Loader />}
      </Text>
    </>
  )
}

export default Header
