import BaffleButton from '@nord/ui/src/components/BaffleButton'
import DescriptionList from '@nord/ui/src/components/DescriptionList'
import Paginator from '@nord/ui/src/components/Paginator'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import React from 'react'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import { COMPANY_PATHS } from '../../../configuration/constants'
import { selectCurrentPortfolio } from '../../../store/current/portfolioId'
import { selectCurrentUser } from '../../../store/current/user'
import ContentContainer from '../ContentContainer'
import ContentTitle from '../ContentTitle'

const labels = {
  email: 'Email',
  phone: 'Telefon',
}

const DisclaimerParagraf = () => (
  <p>
    Du accepterer samtidigt, at NORD.investments må videregive dit ønske til Saxo Bank, om at blive
    handlet i henhold til din risikoprofil og investeringsplan. Dette vil først blive
    videreformidlet, når der er blevet indbetalt penge på din konto.
  </p>
)

const ConfirmationPage = () => {
  const {
    // @ts-expect-error TS(2339) FIXME: Property 'riskScore' does not exist on type 'unkno... Remove this comment to see the full error message
    riskScore,
    // @ts-expect-error TS(2339) FIXME: Property 'fullRiskScoreTitle' does not exist on ty... Remove this comment to see the full error message
    fullRiskScoreTitle,
    // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'unknown'.
    id: portfolioId,
  } = useObjectSelector(selectCurrentPortfolio)
  // @ts-expect-error TS(2339) FIXME: Property 'firstName' does not exist on type 'unkno... Remove this comment to see the full error message
  const { firstName, lastName, email, phone, phonePrefix, companyName } =
    useObjectSelector(selectCurrentUser)

  return (
    <>
      <ContentContainer>
        <ContentTitle title="Oversigt over din oprettelse" />
        <Row>
          <Col sm={{ offset: 1, span: 10 }} md={{ offset: 2, span: 8 }}>
            <p className="text-center">
              Dit virksomhedsdepot vil blive oprettet med følgende oplysninger. Bekræft venligst, at
              de er korrekte.
            </p>
            <Card className="shadow">
              <Card.Body>
                <DescriptionList>
                  <DescriptionList.Item label="Virksomhedsnavn" value={companyName} />
                  <DescriptionList.Item label="Navn" value={`${firstName} ${lastName}`} />
                  <DescriptionList.Item label={labels.email} value={email} />
                  <DescriptionList.Item label={labels.phone} value={`+${phonePrefix} ${phone}`} />
                  <DescriptionList.Item label="Risikoprofil" value={fullRiskScoreTitle} />
                </DescriptionList>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mt-4">
            <p>
              Tryk nedenfor for at godkende disse oplysninger, og afslutte din virksomheds
              oprettelse.
            </p>
            <DisclaimerParagraf />
            <BaffleButton
              variant="primary"
              type={undefined}
              as={Link}
              to={COMPANY_PATHS.verificationComplete(portfolioId)}
            >
              Godkend og afslut
            </BaffleButton>
          </Col>
        </Row>
      </ContentContainer>
      <Paginator.Navigation.Container>
        <BaffleButton
          variant="link"
          className="text-light"
          as={Link}
          type={undefined}
          to={{
            pathname: COMPANY_PATHS.investmentPlanResult(portfolioId),
            search: `?risk_score=${riskScore}`,
          }}
        >
          Tilbage til investeringsplan
        </BaffleButton>
      </Paginator.Navigation.Container>
    </>
  )
}

export default ConfirmationPage
