import BaffleButton from '@nord/ui/src/components/BaffleButton'
import Text from '@nord/ui/src/components/Text'
import { getConfig } from '@nord/ui/src/configuration'
import useApi, { useApiRequest } from '@nord/ui/src/hooks/useApi'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import { useDispatch, useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link, useHistory } from 'react-router-dom'

import { PENSION_PATHS } from '../../../../configuration/constants'
import { selectInitialLoading } from '../../../../store/current/loading'
import { changeCurrentPortfolio } from '../../../../store/current/portfolioId'
import { changeCurrentUser, selectCurrentUser } from '../../../../store/current/user'
import LoadingIcon from '../../../LoadingIcon'
import ContentContainer from '../../ContentContainer'
import ContentTitle from '../../ContentTitle'
import { useCreateInvestmentPlan } from '../InvestmentPlan'

import NordEntry from './NordEntry'
import PensionProviderEntry from './PensionProviderEntry'
import styles from './styles.module.scss'
import TableColumn from './TableColumn'
import useInvestmentplanRedirect from './useInvestmentplanRedirect'
import useKreditDataId from './useKreditdataId'
import { getTitle } from './utilities'

const minimumInvestment = getConfig('numbers.minimumInvestment')

const PensionsInfoStartPage = () => {
  const history = useHistory()
  const loading = useSelector(selectInitialLoading)
  const [submitting, setSubmitting] = useState()
  const createInvestmentPlan = useCreateInvestmentPlan()
  const dispatch = useDispatch()
  const kreditdataId = useKreditDataId()
  // @ts-expect-error TS(2339) FIXME: Property 'cpr' does not exist on type 'unknown'.
  const { cpr, birthdate } = useObjectSelector(selectCurrentUser)

  const fetchPensionsInfoData = useApi('/onboarding/pension/pensions_info_consents', {
    withCredentials: true,
  })

  const { data: pensionsInfoData, loading: loadingPensionsInfoData } = useApiRequest(
    fetchPensionsInfoData,
    {
      payload: { kreditdataId },
      autoCall: !loading && kreditdataId,
    },
  )

  const { data: pensionProviders } = pensionsInfoData || {}

  useEffect(() => {
    if (loadingPensionsInfoData) return
    if (!pensionsInfoData) return

    const { birthdate: newBirthdate, cpr: newCpr } = pensionsInfoData

    dispatch(
      changeCurrentUser({
        kreditdataId,
        birthdate: newBirthdate,
        cpr: newCpr,
        cprLastDigits: newCpr ? newCpr.slice(6, 10) : null,
      }),
    )
  }, [dispatch, kreditdataId, loadingPensionsInfoData, pensionsInfoData])

  useInvestmentplanRedirect()

  const handleSelectPension = async ({
    expectedInvestment,
    key,
    ...portfolioMigrationData
  }: any) => {
    if (submitting) return

    setSubmitting(key)
    await dispatch(
      changeCurrentPortfolio({
        expectedInvestment,
        ...portfolioMigrationData,
      }),
    )

    if (expectedInvestment && expectedInvestment > minimumInvestment) {
      createInvestmentPlan({
        ...portfolioMigrationData,
        kreditdataId,
        birthdate,
        cpr,
      })
    } else {
      history.push(`${PENSION_PATHS.investmentPlan('ny')}?pensions_info=complete`)
    }
  }

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element[]; xs: number; md: numbe... Remove this comment to see the full error message
    <ContentContainer xs={12} md={12} lg={12} xl={{ offset: 1, span: 10 }}>
      <ContentTitle title={getTitle(pensionProviders)} />
      <Text as="p" align="center" className="subheader">
        Her er en liste over alle dine pensioner fra PensionsInfo. Enkelte selskaber kan mangle hvis
        de ikke har en aftale med PensionsInfo.
      </Text>
      <Text as="p" align="center" className="subheader">
        Ikke alle pensioner kan flyttes til NORD og Saxo Bank. Hvis en pension ikke kan flyttes,
        står der en kort beskrivelse om årsagen til dette. Du er altid velkommen til at skrive til
        os hvis du har spørgsmål, eller der er mangler i den viste information.
      </Text>
      <Text as="p" align="center" className="subheader">
        Din investeringsplan er gratis og ikke bindende. Ønsker du at starte en flytning af din
        pension til NORD, kan du gøre dette efter du har oprettet investeringsplanen.
      </Text>
      {pensionProviders ? (
        <div className={styles.pensionsList}>
          <Table responsive="lg">
            <thead>
              <tr className="bg-light">
                {/* @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'ReactElem... Remove this comment to see the full error message */}
                <TableColumn as="th">Aftale og leverandør</TableColumn>
                {/* @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'ReactElem... Remove this comment to see the full error message */}
                <TableColumn as="th">Type</TableColumn>
                {/* @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'ReactElem... Remove this comment to see the full error message */}
                <TableColumn as="th">Opsparing</TableColumn>
                {/* @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'ReactElem... Remove this comment to see the full error message */}
                <TableColumn as="th">Status</TableColumn>
                {/* @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'ReactElem... Remove this comment to see the full error message */}
                <TableColumn as="th">Få din investeringsplan</TableColumn>
              </tr>
            </thead>
            {pensionProviders.map(({ provider, pensions, referenceNumber, ...other }: any) => {
              const key = `${provider.id}-${referenceNumber}`

              return (
                <PensionProviderEntry
                  {...other}
                  {...provider}
                  providerKey={key}
                  key={key}
                  referenceNumber={referenceNumber}
                  pensions={pensions}
                  onSelect={handleSelectPension}
                  kreditdataId={kreditdataId}
                  submitting={submitting}
                />
              )
            })}
            <NordEntry />
          </Table>
        </div>
      ) : (
        // @ts-expect-error TS(2741) FIXME: Property 'children' is missing in type '{}' but re... Remove this comment to see the full error message
        <LoadingIcon />
      )}
      <Row>
        <Col className="d-flex justify-content-center">
          <BaffleButton
            variant="primary"
            as={Link}
            to={`${PENSION_PATHS.investmentPlan('ny')}?pensions_info=skip`}
          >
            Fortsæt uden flytning
          </BaffleButton>
        </Col>
      </Row>
    </ContentContainer>
  )
}

export default PensionsInfoStartPage
