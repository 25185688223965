import NordLogo from '@nord/ui/src/components/NordLogo'
import Paginator from '@nord/ui/src/components/Paginator'
import SaxoLogo from '@nord/ui/src/components/SaxoLogo'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { selectCurrentPortfolio } from '../../../../store/current/portfolioId'
import ChangeAccountTypeButton from '../../ChangeAccountTypeButton'
import ContentTitle from '../../ContentTitle'

const IntroPage = (otherProps: any) => {
  // @ts-expect-error TS(2339) FIXME: Property 'fullRiskScoreTitle' does not exist on ty... Remove this comment to see the full error message
  const { fullRiskScoreTitle, depotType } = useObjectSelector(selectCurrentPortfolio)

  return (
    <Paginator.Form.Page {...otherProps}>
      <ContentTitle title={`Oprettelse af ${depotType} i Saxo Bank`} />
      <Row>
        <Col>
          <p className="text-center h5">
            Risikoprofil:{' '}
            <span className="text-primary font-weight-bold text-underline">
              {fullRiskScoreTitle}
            </span>
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <p className="text-center">
            Nu hvor vi har lagt din investeringsplan, er det tid til at få oprettet dit
            pensionsdepot i Saxo Bank. Bare rolig, vi står for det hele - fra start til slut.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div className="logo-wrapper">
            <div className="logo-inner">
              <NordLogo />
            </div>
            <div className="logo-inner d-none d-sm-block text-center">
              <div>i samarbejde med</div>
            </div>
            <div className="logo-inner">
              <SaxoLogo height={70} />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <Paginator.Form.Navigation.NextButton>
            Start oprettelse af din pension
          </Paginator.Form.Navigation.NextButton>
        </Col>
      </Row>
      <Row>
        <Col className="text-center mt-4">
          <ChangeAccountTypeButton />
        </Col>
      </Row>
    </Paginator.Form.Page>
  )
}

export default IntroPage
