import NordHeader from '@nord/ui/src/components/Header'
import LogOutButton from '@nord/ui/src/components/LogOutButton'
import { getConfig } from '@nord/ui/src/configuration'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import useQuery from '@nord/ui/src/hooks/useQuery'
import classNames from 'classnames'
import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import { PATHS } from '../../../configuration/constants'
import useFlow from '../../../hooks/useFlow'
import useSignedIn from '../../../hooks/useSignedIn'
import { selectCurrentPortfolioId } from '../../../store/current/portfolioId'
import { selectCurrentUser } from '../../../store/current/user'
import { selectProgress } from '../../../store/ui/progressSlice'
import Breadcrumbs from '../../Breadcrumbs'
import { useReferralContext } from '../Referral/ReferralContext'

import styles from './index.module.scss'

const STAGES = ['Investeringsplan', 'Opret depot', 'Bekræft']

const appFrontpageUrl = getConfig('urls.app.frontpage')

export interface Props {
  page: any // TODO: pagePropType()
  progress?: number
}

const Header = ({ page }: Props) => {
  const progress = useSelector(selectProgress)
  const signedIn = useSignedIn()
  const flow = useFlow()
  const referralTokenQuery = useQuery('referralToken')
  const portfolioId = useSelector(selectCurrentPortfolioId)
  // @ts-expect-error TS(2339) FIXME: Property 'appState' does not exist on type 'unknow... Remove this comment to see the full error message
  const { appState } = useObjectSelector(selectCurrentUser)

  const { referralIsValid, onPageLeave } = useReferralContext()

  const { path, stage, progress: staticProgress } = page
  const showAppLink = appState && appState !== 'user_created'

  const loginPath = referralTokenQuery
    ? `${PATHS.shared.userSignIn()}?redirect_to=${PATHS.shared.referral(referralTokenQuery)}`
    : PATHS.shared.userSignIn()

  let breadcrumbs
  if (
    stage !== null &&
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    (signedIn || (flow && path !== PATHS[flow].investmentPlanResult(portfolioId)))
  ) {
    breadcrumbs = (
      <Breadcrumbs stages={STAGES} stage={stage} progress={staticProgress || progress} />
    )
  } else {
    breadcrumbs = <div className="spacer py-5" />
  }

  return (
    <NordHeader
      // TODO: Remove this hacky styling when HeadersWrapper is done
      className={classNames(styles.header, {
        [styles.referralBannerOffset]: referralIsValid,
      })}
      footer={<div className="position-relative">{breadcrumbs}</div>}
    >
      <Navbar className={styles.navbar} role="banner">
        <NordHeader.Logo variant="light" />
        <Nav className="d-flex justify-content-between">
          <Nav.Item className={classNames({ [styles.hidden]: !showAppLink })}>
            <NordHeader.Link href={appFrontpageUrl}>Gå til app</NordHeader.Link>
          </Nav.Item>
          {signedIn ? (
            // @ts-expect-error TS(2739) FIXME: Type '{ ({ className, children, ...otherProps }: P... Remove this comment to see the full error message
            <LogOutButton as={NordHeader.Link} />
          ) : (
            <NordHeader.Link as={Link} to={loginPath} onClick={referralTokenQuery && onPageLeave}>
              Log ind
            </NordHeader.Link>
          )}
        </Nav>
      </Navbar>
    </NordHeader>
  )
}

Header.defaultProps = {
  progress: undefined,
}

export default Header
