import Icon from '@nord/ui/src/components/Icon'
import LoadingButton from '@nord/ui/src/components/LoadingButton'
import classNames from 'classnames'
import React from 'react'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowRight from 'react-feather/dist/icons/arrow-right'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import CheckSquare from 'react-feather/dist/icons/check-square'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Square from 'react-feather/dist/icons/square'

import LoadingIcon from '../../../../LoadingIcon'
import ContentContainer from '../../../ContentContainer'
import ContentTitle from '../../../ContentTitle'

export interface Props {
  documents: {
    [key: string]: any
  } // TODO: documentPropType()
  onConfirm: (...args: any[]) => any
  onShowDocument: (...args: any[]) => any
  loading: boolean
}

const Content = ({ documents, onConfirm, onShowDocument, loading }: Props) => {
  const documentsRead =
    Object.keys(documents).length > 0 && Object.values(documents).every((document) => document.read)

  return (
    <ContentContainer>
      <ContentTitle title="Dokumenter" />
      <Row>
        <Col sm={{ offset: 1, span: 10 }} md={{ offset: 2, span: 8 }}>
          <p className="text-center">Læs og accepter nedenstående dokumenter for at fortsætte.</p>
          <p className="text-center">Vi sender dokumenterne til din email efterfølgende.</p>
          <Card>
            <Card.Body>
              {Object.keys(documents).length <= 0 ? (
                <div>
                  <LoadingIcon>Vi henter dine dokumenter, vent et øjeblik...</LoadingIcon>
                </div>
              ) : (
                <ul className="list-unstyled">
                  {Object.entries(documents).map(([key, document]) => (
                    <li key={key} className="mt-2">
                      <a
                        href={document.url}
                        onClick={(event) => onShowDocument(key, event)}
                        className={classNames({
                          'text-dark': document.read,
                        })}
                      >
                        <Icon icon={document.read ? CheckSquare : Square} />
                        <span className="pl-2">{document.filename}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-center mt-5">
            <p>Jeg har læst, forstået, og accepterer ovenstående dokumenter</p>
            <LoadingButton
              icon={ArrowRight}
              // @ts-expect-error TS(2322) FIXME: Type '{ children: string; icon: any; iconPlacement... Remove this comment to see the full error message
              iconPlacement="last"
              variant="primary"
              onClick={onConfirm}
              loading={loading}
              disabled={!documentsRead}
            >
              Accepter dokumenter og fortsæt
            </LoadingButton>
          </div>
        </Col>
      </Row>
    </ContentContainer>
  )
}

export default Content
