import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import React from 'react'

import useFlow from '../../../../hooks/useFlow'
import { selectCurrentUser } from '../../../../store/current/user'
import CompletePageContainer from '../../CompletePageContainer'

const CompletePage = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'email' does not exist on type 'unknown'.
  const { email } = useObjectSelector(selectCurrentUser)
  const flow = useFlow()

  return (
    <CompletePageContainer>
      <p className="text-center px-5">
        Vi behandler din oprettelse hurtigst muligt, og giver dig besked på
        <span className="text-primary">{` ${email} `}</span>, når din konto er klar til at modtage
        overførsler. Det tager typisk ikke mere end {flow === 'company' ? '4-5 dage' : 'en dag'}. Du
        skal ikke foretage dig mere nu.
      </p>
    </CompletePageContainer>
  )
}

export default CompletePage
