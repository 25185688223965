import AlertModal from '@nord/ui/src/components/AlertModal'
import CurrencyInput from '@nord/ui/src/components/CurrencyInput'
import Form from '@nord/ui/src/components/Form'
import Paginator from '@nord/ui/src/components/Paginator'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import { useFormikContext } from 'formik'
import React, { useState, useRef, useCallback } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { MINIMUM_INVESTMENT } from '../../../../../configuration/constants'
import { selectCurrentUser } from '../../../../../store/current/user'
import ContentTitle from '../../../ContentTitle'
import { investmentLimitForDocumentation } from '../../../Private/Onboarding/InvestmentPage'
import { useUploadSourceOfFunds } from '../../../Shared/upload-hooks'
import SourceOfFundsField from '../../../SourceOfFundsField'

import labels, { fields } from './labels'
import validationSchema from './validationSchema'

const InvestmentPage = ({ ...otherProps }) => {
  const {
    values: { expectedInvestment },
  } = useFormikContext<any>()
  const [showWarning, setShowWarning] = useState(false)
  // @ts-expect-error TS(2339) FIXME: Property 'email' does not exist on type 'unknown'.
  const { email, amlDocumentationRequired } = useObjectSelector(selectCurrentUser)
  const uploadedFiles = useRef([])
  const uploadSourceOfFunds = useUploadSourceOfFunds()

  const addUploadedFile = (newFile: any) => {
    // @ts-expect-error TS(2322) FIXME: Type 'any' is not assignable to type 'never'.
    uploadedFiles.current = [newFile, ...uploadedFiles.current]
  }

  const handleSubmit = useCallback(
    async ({ sourceOfFunds }: any) => {
      await uploadSourceOfFunds({
        sourceOfFunds,
        onUpload: addUploadedFile,
        uploadedFiles: uploadedFiles.current,
        email,
      })
    },
    [email, uploadSourceOfFunds],
  )

  const checkExpectedInvestment = () => {
    if (!expectedInvestment) return true

    if (parseInt(expectedInvestment, 10) < MINIMUM_INVESTMENT) {
      setShowWarning(true)

      return false
    }

    return true
  }

  const handleBlurExpectedInvestment = () => {
    checkExpectedInvestment()

    return true
  }

  const handleHideAlert = () => setShowWarning(false)

  const minimumInvestmentText = numberToCurrency(MINIMUM_INVESTMENT)

  return (
    <Paginator.Form.Page
      labelDescriptions={labels}
      validationSchema={validationSchema}
      fields={fields}
      onSubmit={handleSubmit}
      {...otherProps}
    >
      <ContentTitle title="Vi skal bruge lidt information om din økonomi" />
      <Row>
        <Col xs={12}>
          <Form.FieldGroup name="expectedInvestment">
            {/* @ts-expect-error TS(2739) FIXME: Type '{ ({ maskOptions, value, ...otherProps }: Cu... Remove this comment to see the full error message */}
            <Form.Field as={CurrencyInput} onBlur={handleBlurExpectedInvestment} />
          </Form.FieldGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Form.FieldGroup name="investableAssets">
            {/* @ts-expect-error TS(2322) FIXME: Type '{ ({ maskOptions, value, ...otherProps }: Cu... Remove this comment to see the full error message */}
            <Form.Field as={CurrencyInput} />
          </Form.FieldGroup>
        </Col>
      </Row>

      {(expectedInvestment > investmentLimitForDocumentation || amlDocumentationRequired) && (
        <Row>
          <Col xs={12}>
            <SourceOfFundsField path={`/${email}/temp`} />
          </Col>
        </Row>
      )}
      <AlertModal
        onHide={handleHideAlert}
        onAccept={handleHideAlert}
        title={`Minimumsindskud er ${minimumInvestmentText}`}
        text={`Vær opmærksom på, at NORD.investments minimumsindskud er på ${minimumInvestmentText} Det er ikke muligt med den brede aktivallokering som porteføljerne har, at konstruere en veldiversificeret portefølje for under ${minimumInvestmentText}`}
        show={showWarning}
      />
    </Paginator.Form.Page>
  )
}

export default InvestmentPage
