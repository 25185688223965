import AppButton from '@nord/ui/src/components/AppButton'
import BaffleButton from '@nord/ui/src/components/BaffleButton'
import { getConfig } from '@nord/ui/src/configuration'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import { clearCurrentPortfolioId } from '@nord/ui/src/store/current/portfolioId'
import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useSelector, useDispatch } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import { PATHS, URLS } from '../../configuration/constants'
import useFlow from '../../hooks/useFlow'
import {
  completeCurrentPortfolioOnboarding,
  selectCurrentPortfolio,
  selectCurrentPortfolioId,
  selectOnboardingState,
} from '../../store/current/portfolioId'
import { selectCurrentUser } from '../../store/current/user'
import { pushEventToDataLayer } from '../../utilities/pushToDataLayer'

import ContentContainer from './ContentContainer'
import ContentTitle from './ContentTitle'
import LeadSourcesModal from './Shared/LeadSourcesModal'

const appFrontpageUrl = getConfig('urls.app.frontpage')

export interface Props {
  children: React.ReactNode
}

const CompletePageContainer = ({ children, ...otherProps }: Props) => {
  const dispatch = useDispatch()
  const portfolioId = useSelector(selectCurrentPortfolioId)
  const flow = useFlow()
  const currentOnboardingState = useSelector(selectOnboardingState)
  // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'unknown'.
  const { id: userId, onboardingState: userOnboardingState } = useObjectSelector(selectCurrentUser)
  // @ts-expect-error TS(2339) FIXME: Property 'readyToOnboardAt' does not exist on type... Remove this comment to see the full error message
  const { readyToOnboardAt, expectedInvestment } = useObjectSelector(selectCurrentPortfolio)

  const [showModal, setShowModal] = useState(userOnboardingState !== 'saxo_onboarded')

  const handleHide = () => setShowModal(false)

  const handleStartNewOnboarding = () => {
    dispatch(clearCurrentPortfolioId())
  }

  useEffect(() => {
    if (!portfolioId) return

    if (currentOnboardingState === 'saxo_verified') {
      pushEventToDataLayer({
        event: 'saxo_verified',
        userID: userId,
        expectedInvestment,
        flow,
      })
    }

    if (
      !readyToOnboardAt &&
      (currentOnboardingState === 'saxo_verified' || currentOnboardingState === 'kyc_obtained')
    ) {
      const asyncCall = async () => {
        // @ts-expect-error TS(2339) FIXME: Property 'userOnboardingState' does not exist on t... Remove this comment to see the full error message
        const { userOnboardingState: userOnboardingStateResponse } = await dispatch(
          completeCurrentPortfolioOnboarding(portfolioId),
        )

        pushEventToDataLayer({
          event: 'ready_to_onboard',
          userID: userId,
          expectedInvestment,
          flow,
        })

        if (userOnboardingStateResponse === 'submitted_to_saxo') {
          pushEventToDataLayer({
            event: 'submitted_to_saxo',
            userID: userId,
            expectedInvestment,
            flow,
          })
        }
      }

      asyncCall()
    }
  }, [
    currentOnboardingState,
    dispatch,
    expectedInvestment,
    flow,
    portfolioId,
    readyToOnboardAt,
    userId,
  ])

  return (
    <>
      {/* @ts-expect-error TS(2322) FIXME: Type '{ show: boolean; onHide: () => void; }' is n... Remove this comment to see the full error message */}
      <LeadSourcesModal show={showModal} onHide={handleHide} />
      <ContentContainer {...otherProps}>
        <ContentTitle title="Tak for din oprettelse" />
        <Row>
          <Col>{children}</Col>
        </Row>
        {flow !== 'company' && (
          <>
            <ContentTitle title="Vil du oprette flere depoter?" fontSize="lg" />
            <Row>
              <Col>
                <div className="d-flex align-items-center justify-content-center flex-wrap">
                  {flow === 'pension' && (
                    <BaffleButton
                      className="m-2"
                      variant="primary"
                      as={Link}
                      type={undefined}
                      // @ts-expect-error TS(2322) FIXME: Type '{ children: string; className: string; varia... Remove this comment to see the full error message
                      to={PATHS.private.investmentPlan()}
                      onClick={handleStartNewOnboarding}
                    >
                      Tilføj et ekstra frie midler depot
                    </BaffleButton>
                  )}
                  <BaffleButton
                    className="m-2"
                    variant="primary"
                    as={Link}
                    type={undefined}
                    // @ts-expect-error TS(2322) FIXME: Type '{ children: string; className: string; varia... Remove this comment to see the full error message
                    to={PATHS.pension.investmentPlan()}
                    onClick={handleStartNewOnboarding}
                  >
                    Opret eller flyt pensionsdepot
                  </BaffleButton>
                </div>
              </Col>
            </Row>
          </>
        )}
        <ContentTitle title="Har du spørgsmål?" fontSize="lg" className="mt-5" />
        <Row>
          <Col>
            <div className="text-center">
              <div className="text-label">Telefon</div>
              <div className="text-primary">
                <a href="tel:+45 71969628">+45 7196 9628</a>
              </div>
              <div className="text-label mt-3">Email</div>
              <div className="text-primary">
                <a href="mailto:hello@nord.investments">hello@nord.investments</a>
              </div>
              {/*
                <div className="text-label mt-3">Følg os på facebook</div>
                <div>
                  FACEBOOK LIKE BUTTON
                </div>
              */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="text-center mt-5">
              <p>Hent vores app, så er du klar, når din oprettelse er behandlet!</p>
              <div>
                <AppButton />
              </div>
            </div>
            <div className="d-flex justify-content-center children-mx-2 mt-5">
              <BaffleButton href={URLS.frontpage} variant="link" as="a">
                Tilbage til forsiden
              </BaffleButton>
              <BaffleButton href={appFrontpageUrl} variant="link" as="a">
                Gå til vores app
              </BaffleButton>
            </div>
          </Col>
        </Row>
      </ContentContainer>
    </>
  )
}

export default CompletePageContainer
