import DescriptionList from '@nord/ui/src/components/DescriptionList'
import Select from '@nord/ui/src/components/Select'
import StickyCard from '@nord/ui/src/components/StickyCard'
import Text from '@nord/ui/src/components/Text'
import { numberToString } from '@nord/ui/src/utilities/numberFormatter'
import capitalize from 'lodash/capitalize'
import times from 'lodash/times'
import React from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import LoadingIcon from '../../../LoadingIcon'
import ContentTitle from '../../ContentTitle'
import { useReferralContext } from '../../Referral/ReferralContext'

export const MAX_RISK_SCORE = 6

const buildOptions = (personalized: boolean, advisedRiskScore?: number) => {
  const options = times(MAX_RISK_SCORE, (value) => {
    const riskScore = value + 1

    return {
      label: `P${riskScore} (uden nedtrapning)`,
      value: {
        riskScore,
        escalation: false,
      },
    }
  }).reverse()

  if (personalized && advisedRiskScore) {
    options.unshift({
      label: `P${advisedRiskScore} (med nedtrapning) - Vores anbefaling`,
      value: {
        riskScore: advisedRiskScore,
        escalation: true,
      },
    })
  } else {
    options.unshift({
      label: `P6 (med nedtrapning)`,
      value: {
        riskScore: 6,
        escalation: true,
      },
    })
  }

  return options
}

export interface PensionInfoProps {
  riskScore: number
  advisedRiskScore?: number
  depotType: string
  loading: boolean
  onboardingButton?: React.ReactNode
  yearsToPension?: number
  age?: number
  escalation: boolean
  personalized: boolean
  showSelect: boolean
  submittedToSaxo: boolean
  onChange(value: unknown): void
  onChangeShowSelect(value: unknown): void
}

const PensionInfo = ({
  riskScore,
  advisedRiskScore,
  depotType,
  loading,
  onboardingButton,
  yearsToPension,
  age,
  escalation,
  personalized,
  showSelect,
  submittedToSaxo,
  onChange,
  onChangeShowSelect,
}: PensionInfoProps) => {
  const { referralIsValid } = useReferralContext()
  const handleShowSelect = () => onChangeShowSelect(true)

  const options = buildOptions(personalized, advisedRiskScore)

  const selectContent = (
    <div data-cy="input-riskScore">
      <Select
        // @ts-expect-error TS(2322) FIXME: Type '{ name: string; options: { label: string; va... Remove this comment to see the full error message
        name="riskScore"
        options={options}
        onValueChange={onChange}
        placeholder="Vælg risikoprofil..."
      />
    </div>
  )

  return (
    <StickyCard
      className="mb-grid"
      header={
        <div className="text-center w-100 my-1">
          <div>
            <Text size="xl">{riskScore}</Text>
            <Text size="md">{`/${MAX_RISK_SCORE}`}</Text>
          </div>
          {escalation ? '(med nedtrapning)' : '(uden nedtrapning)'}
          <div className="text-center mt-3">
            {showSelect ? (
              selectContent
            ) : (
              <Button
                variant="link"
                className="text-secondary font-size-xs p-0"
                onClick={handleShowSelect}
              >
                (vælg anden risikoprofil)
              </Button>
            )}
          </div>
        </div>
      }
      content={
        loading ? (
          // @ts-expect-error TS(2741) FIXME: Property 'children' is missing in type '{}' but re... Remove this comment to see the full error message
          <LoadingIcon />
        ) : (
          <>
            {personalized ? (
              <>
                <Row>
                  <Col>
                    {/* @ts-expect-error TS(2322) FIXME: Type '{ title: string; id: string; }' is not assig... Remove this comment to see the full error message */}
                    <ContentTitle title="Din pension" id="din-pension" />
                  </Col>
                </Row>
                <DescriptionList>
                  <DescriptionList.Item label="Pensionstype" value={capitalize(depotType)} />
                  <DescriptionList.Item label="Alder" value={`${age} år`} />
                  <DescriptionList.Item
                    label="År til forventet pensionsalder"
                    value={numberToString(yearsToPension)}
                  />
                </DescriptionList>
              </>
            ) : (
              <Row>
                <Col>
                  {/* @ts-expect-error TS(2322) FIXME: Type '{ title: string; id: string; }' is not assig... Remove this comment to see the full error message */}
                  <ContentTitle title="Risikoprofil" id="risikoprofil" />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                {personalized && <h3 className="text-center h5 mt-5">Risikoprofil</h3>}
                <Text className="text-primary mt-3">
                  <span className="font-size-lg">P{riskScore}</span>
                  <span className="font-size-md"> af {MAX_RISK_SCORE} mulige</span>
                  <Text align="center" as="div" size="sm" variant="muted">
                    {escalation ? '(med nedtrapning)' : '(uden nedtrapning)'}
                  </Text>
                </Text>
                {!submittedToSaxo && (
                  <div className="text-center mt-3">
                    {showSelect ? (
                      selectContent
                    ) : (
                      <Button
                        variant="link"
                        className="text-muted font-size-xs p-0"
                        onClick={handleShowSelect}
                      >
                        (vælg anden risikoprofil)
                      </Button>
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </>
        )
      }
      footer={<div className="text-center mt-5">{onboardingButton}</div>}
      // TODO: Remove this prop also in storybook when HeadersWrapper is done
      withReferralBanner={referralIsValid}
    />
  )
}

PensionInfo.defaultProps = {
  advisedRiskScore: undefined,
  age: undefined,
  onboardingButton: undefined,
  yearsToPension: undefined,
}

export default PensionInfo
