import AlertModal from '@nord/ui/src/components/AlertModal'
import CurrencyInput from '@nord/ui/src/components/CurrencyInput'
import Form from '@nord/ui/src/components/Form'
import Paginator from '@nord/ui/src/components/Paginator'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import { useFormikContext } from 'formik'
import capitalize from 'lodash/capitalize'
import React, { useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { object, number } from 'yup'

import { MINIMUM_PENSION_INVESTMENT } from '../../../../configuration/constants'
import ContentTitle from '../../ContentTitle'

const validationSchema = object().shape({
  expectedInvestment: number().required().safeInteger().min(MINIMUM_PENSION_INVESTMENT),
})

const labels = {
  expectedInvestment: 'Hvor meget forventer du at flytte eller indbetale i løbet af det første år?',
}

const fields = Object.keys(labels)

const ExpectedInvestmentPage = (otherProps: any) => {
  const {
    values: { expectedInvestment, depotType },
  } = useFormikContext<any>()
  const [showWarning, setShowWarning] = useState(false)

  const checkInvestment = () => {
    if (expectedInvestment && expectedInvestment < MINIMUM_PENSION_INVESTMENT) {
      setShowWarning(true)

      return false
    }

    return true
  }

  const handleSubmit = (event: any) => {
    if (!checkInvestment()) {
      event.preventDefault()
    }
  }

  const handleBlurInvestment = () => {
    checkInvestment()

    return true
  }

  const handleHideAlert = () => setShowWarning(false)

  const minimumPensionInvestmentText = numberToCurrency(MINIMUM_PENSION_INVESTMENT)

  return (
    <Paginator.Form.Page
      validationSchema={validationSchema}
      fields={fields}
      labelDescriptions={labels}
      {...otherProps}
    >
      <ContentTitle title={`Hvor stor er din ${depotType ? capitalize(depotType) : 'pension'}?`} />
      <Row>
        <Col xs={12}>
          <Form.FieldGroup name="expectedInvestment">
            {/* @ts-expect-error TS(2739) FIXME: Type '{ ({ maskOptions, value, ...otherProps }: Cu... Remove this comment to see the full error message */}
            <Form.Field as={CurrencyInput} onBlur={handleBlurInvestment} autoFocus />
          </Form.FieldGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="text-center">
          {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; variant: string; onClick... Remove this comment to see the full error message */}
          <Form.SubmitButton variant="primary" onClick={handleSubmit} loadingIcon>
            Se investeringsplanen
          </Form.SubmitButton>
        </Col>
      </Row>
      <AlertModal
        onHide={handleHideAlert}
        onAccept={handleHideAlert}
        title={`Minimumsbeløb er ${minimumPensionInvestmentText}`}
        text={`Vær opmærksom på, at vi har et minimumsbeløb på ${minimumPensionInvestmentText} for pensionsdepoter.`}
        show={showWarning}
      />
    </Paginator.Form.Page>
  )
}

export default ExpectedInvestmentPage
