import pushToDataLayer from '@nord/ui/src/utilities/pushToDataLayer'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useLocation, Switch, Route, Redirect, useHistory } from 'react-router-dom'

import { FLOW_PATHS, SHARED_PATHS } from '../../configuration/constants'
import useRedirect from '../../hooks/useRedirect'
import useRouteMatchPortfolioId from '../../hooks/useRouteMatchPortfolioId'
import useSignedIn from '../../hooks/useSignedIn'
import { selectInitialLoading } from '../../store/current/loading'
import { selectCurrentPortfolioId, setCurrentPortfolioId } from '../../store/current/portfolioId'
import { selectPortfolioIds } from '../../store/current/portfolios'

import ChangePage from './ChangePage'
import { PAGES } from './constants'
import Header from './Header'
import Page from './Page'
import PageTransition from './PageTransition'
import Referral from './Referral'
import NotFoundPage from './Shared/NotFoundPage'

const renderRouteContent = (page: any) => {
  const { path, title } = page

  return (
    <>
      {/* @ts-expect-error TS(2741) FIXME: Property 'children' is missing in type '{ currentP... Remove this comment to see the full error message */}
      <ChangePage currentPath={path} currentTitle={title} />

      <Header page={page} />
      <main className="pt-6 mt-6 mt-lg-0">
        <Page page={page} />
      </main>
    </>
  )
}

const PagesWrapper = () => {
  const dispatch = useDispatch()
  const signedIn = useSignedIn()
  const location = useLocation()
  const history = useHistory()
  const matchId = useRouteMatchPortfolioId()
  const portfolioId = useSelector(selectCurrentPortfolioId)
  const loading = useSelector(selectInitialLoading)
  const portfolioIds = useSelector(selectPortfolioIds)
  const { pathname: currentPath } = location

  useEffect(() => {
    pushToDataLayer({
      experimentId: process.env.REACT_APP_GOOGLE_OPTIMIZE_EXPERIMENT_ID,
      variationId: process.env.REACT_APP_GOOGLE_OPTIMIZE_VARIATION_ID,
    })
  }, [])

  useRedirect()

  useEffect(() => {
    if (loading) return
    if (signedIn) return

    if (matchId !== undefined && matchId !== 'ny') {
      history.push({
        pathname: SHARED_PATHS.userSignIn(),
        search: `?redirect_to=${currentPath}`,
      })
    }
  }, [matchId, history, currentPath, signedIn, loading])

  useEffect(() => {
    if (!signedIn) return

    if (matchId !== 'ny' && portfolioId !== matchId && portfolioIds.includes(matchId))
      dispatch(setCurrentPortfolioId(matchId))
  }, [dispatch, matchId, portfolioId, portfolioIds, signedIn])

  const pages = [...PAGES.legacy, ...PAGES.initial].filter(
    (page) => page.requireSignIn === signedIn || page.requireSignIn === null,
  )

  return (
    <>
      <PageTransition>
        <Switch location={location}>
          {pages.map((page) => (
            <Route key={page.path} path={page.path} render={() => renderRouteContent(page)} exact />
          ))}
          {Object.entries(FLOW_PATHS).map(([currentFlow, path]) => {
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            const flowPages = PAGES[currentFlow].filter(
              (page: any) => page.requireSignIn === signedIn || page.requireSignIn === null,
            )

            return (
              <Route key={currentFlow} path={path}>
                <Switch location={location}>
                  {flowPages.map((page: any) => (
                    <Route
                      key={page.path}
                      path={page.path}
                      render={() => renderRouteContent(page)}
                      exact
                    />
                  ))}
                  <Route path="*" key={`${currentFlow}/*`}>
                    <NotFoundPage />
                  </Route>
                </Switch>
              </Route>
            )
          })}
          {signedIn ? (
            <Route path="*">
              <NotFoundPage />
            </Route>
          ) : (
            <Redirect
              from="*"
              to={{
                pathname: SHARED_PATHS.userSignIn(),
                search: `?redirect_to=${currentPath}`,
              }}
            />
          )}
        </Switch>
      </PageTransition>
      <Referral.Banner />
    </>
  )
}

export default PagesWrapper
