import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import React from 'react'

import { selectCurrentPortfolio } from '../../../store/current/portfolioId'
import { selectCurrentUser } from '../../../store/current/user'
import CompletePageContainer from '../CompletePageContainer'

const CompletePage = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'migrationDepot' does not exist on type '... Remove this comment to see the full error message
  const { migrationDepot, migrationDepotCompanyName } = useObjectSelector(selectCurrentPortfolio)
  // @ts-expect-error TS(2339) FIXME: Property 'email' does not exist on type 'unknown'.
  const { email } = useObjectSelector(selectCurrentUser)

  let content
  if (migrationDepot) {
    content = (
      <>
        <p className="text-center">
          Når din konto er oprettet i Saxo Bank giver vi dig besked på{' '}
          <span className="text-primary">{email}</span>, hvorefter Saxo Bank igangsætter flytningen
          af dit depot fra {migrationDepotCompanyName}.
        </p>
        <p className="text-center">Bemærk at en flytning af et depot kan tage op til 6-8 uger.</p>
      </>
    )
  } else {
    content = (
      <p className="text-center">
        Vi behandler din oprettelse hurtigst muligt, og giver dig besked på{' '}
        <span className="text-primary">{email}</span>, når din konto er klar til at modtage
        overførsler. Det tager typisk ikke mere end en dag. Du skal ikke foretage dig mere nu.
      </p>
    )
  }

  return <CompletePageContainer>{content}</CompletePageContainer>
}

export default CompletePage
