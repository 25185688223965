import Cookies from 'js-cookie'
import pick from 'lodash/pick'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useDispatch, useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import useFlow from '../../../../hooks/useFlow'
import { selectCurrentUser, createUser } from '../../../../store/current/user'
import getGaClientId from '../../../../utilities/getGaClientId'

import FormContent from './FormContent'

export interface Props {
  onClose: (...args: any[]) => any
  onSignUp?: (...args: any[]) => any
  show?: boolean
}

const SignUpModal = ({ onClose, onSignUp, show, ...props }: Props) => {
  const dispatch = useDispatch()
  const flow = useFlow()
  const [errors, setErrors] = useState({})
  const currentUserValues = useSelector(selectCurrentUser)

  const gaClientId = getGaClientId()

  const handleSignUp = async (values: any) => {
    const signUpValues = {
      ...pick(values, ['email', 'firstName', 'lastName', 'phone', 'phoneCode']),
      ...pick(currentUserValues, ['kreditdataId', 'birthdate', 'cpr']),
    }
    const utmAttributes = Cookies.getJSON('utm')
    const accountType = flow === 'company' ? 'company_account' : 'private_account'

    let data
    try {
      data = await dispatch(
        // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
        createUser({
          ...signUpValues,
          accountType,
          utm: utmAttributes,
          gaClientId,
          errorHandling: {
            ignore: {
              client: true,
            },
          },
        }),
      )
    } catch (error) {
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      setErrors(error.data.errors)
    }

    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    await onSignUp(data)
  }

  return (
    <Modal
      className="text-dark"
      size="lg"
      backdrop="static"
      keyboard={false}
      show={show}
      tabIndex={-1}
      centered
      enforceFocus
    >
      <Modal.Header>
        <div className="w-100">
          <Modal.Title className="w-100 text-center" data-cy="modal-title">
            Få din investeringsplan på mail
          </Modal.Title>

          <p className="w-100 text-center text-muted">
            For at kunne tilgå den igen, bedes du udfylde nedenstående.
          </p>
        </div>
      </Modal.Header>

      <Modal.Body>
        <FormContent
          {...props}
          // @ts-expect-error TS(2322) FIXME: Type '{ onSubmit: (values: any) => Promise<void>; ... Remove this comment to see the full error message
          onSubmit={handleSignUp}
          errors={errors}
          loading={!show}
          buttonText="Vis min investeringsplan"
        />
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link onClick={onClose} className="d-block text-center pb-4">
          Ønsker du ikke at oprette dig eller få planen per mail?
        </Link>
      </Modal.Body>
    </Modal>
  )
}

SignUpModal.defaultProps = {
  onSignUp: (data: any) => data,
  show: undefined,
}

export default SignUpModal
