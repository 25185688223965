import BaffleButton from '@nord/ui/src/components/BaffleButton'
import DescriptionList from '@nord/ui/src/components/DescriptionList'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import React from 'react'
import Card from 'react-bootstrap/Card'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import { PRIVATE_PATHS } from '../../../configuration/constants'
import { selectCurrentPortfolio } from '../../../store/current/portfolioId'
import { selectCurrentUser } from '../../../store/current/user'
import ConfirmationPageContainer from '../ConfirmationPageContainer'

const labels = {
  email: 'Email',
  phone: 'Telefon',
}

const ConfirmationPage = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'fullRiskScoreTitle' does not exist on ty... Remove this comment to see the full error message
  const { fullRiskScoreTitle, id: portfolioId } = useObjectSelector(selectCurrentPortfolio)
  // @ts-expect-error TS(2339) FIXME: Property 'firstName' does not exist on type 'unkno... Remove this comment to see the full error message
  const { firstName, lastName, email, phone, phonePrefix } = useObjectSelector(selectCurrentUser)

  return (
    <ConfirmationPageContainer
      actionContent={
        <>
          <p className="mt-4">
            Når du klikker nedenfor vil du blive henvist til en anden side, med dine dokumenter som
            skal bekræftes, før du til sidst kan verificere dig med NemID eller MitID.
          </p>
          <p>
            Du accepterer samtidigt, at NORD.investments må videregive dit ønske til Saxo Bank, om
            at blive handlet i henhold til din risikoprofil og investeringsplan. Dette vil først
            blive videreformidlet, når der er blevet indbetalt penge på din konto.
          </p>
          <BaffleButton
            data-cy="next-button"
            variant="primary"
            type={undefined}
            as={Link}
            to={PRIVATE_PATHS.documents(portfolioId)}
          >
            Bekræft og fortsæt
          </BaffleButton>
        </>
      }
    >
      <>
        <p className="text-center">
          Dit investeringsdepot vil blive oprettet med følgende oplysninger. Bekræft venligst, at de
          er korrekte.
        </p>
        <Card className="shadow">
          <Card.Body>
            <DescriptionList>
              <DescriptionList.Item label="Navn" value={`${firstName} ${lastName}`} />
              <DescriptionList.Item label={labels.email} value={email} />
              <DescriptionList.Item label={labels.phone} value={`+${phonePrefix} ${phone}`} />
              <DescriptionList.Item label="Risikoprofil" value={fullRiskScoreTitle} />
            </DescriptionList>
          </Card.Body>
        </Card>
      </>
    </ConfirmationPageContainer>
  )
}

export default ConfirmationPage
