import LoginForm from '@nord/ui/src/components/LoginForm'
import { getConfig } from '@nord/ui/src/configuration'
import useRedirectPath from '@nord/ui/src/hooks/useRedirectPath'
import { setCurrentPortfolioId } from '@nord/ui/src/store/current/portfolioId'
import filter from 'lodash/filter'
import React from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ArrowLeft from 'react-feather/dist/icons/arrow-left'
import { useDispatch } from 'react-redux'

import { SHARED_PATHS } from '../../../../configuration/constants'
import IconButtonLink from '../../../IconButtonLink'
import ContentContainer from '../../ContentContainer'

const webappUrl = getConfig('domains.app')

const SignInPage = () => {
  const dispatch = useDispatch()
  const redirectPath = useRedirectPath()

  const handleSignIn = async (userData: any) => {
    const { portfolios, appState } = userData
    if (redirectPath) return

    const portfoliosInOnboarding = filter(portfolios, (portfolio) => !portfolio.readyToOnboardAt)

    const hasPortfoliosInOnboarding = portfoliosInOnboarding.length > 0

    if (appState === 'invested' || appState === 'saxo_onboarded') {
      const redirectUrl = hasPortfoliosInOnboarding ? `${webappUrl}/konto/oprettelser` : webappUrl

      window.location.assign(redirectUrl)
    } else if (hasPortfoliosInOnboarding) {
      dispatch(setCurrentPortfolioId(portfoliosInOnboarding[0].id))
    }
  }

  return (
    <ContentContainer>
      <LoginForm onSignIn={handleSignIn}>
        <IconButtonLink
          variant="link"
          icon={ArrowLeft}
          iconPlacement="first"
          className="pl-lg-0"
          to={SHARED_PATHS.root()}
        >
          Tag en investeringsplan
        </IconButtonLink>
      </LoginForm>
    </ContentContainer>
  )
}

export default SignInPage
