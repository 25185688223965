import BaffleButton from '@nord/ui/src/components/BaffleButton'
import Link from '@nord/ui/src/components/Link'
import Paginator from '@nord/ui/src/components/Paginator'
import { type PageProps } from '@nord/ui/src/components/Paginator/Page'
import Text from '@nord/ui/src/components/Text'
import { getConfig } from '@nord/ui/src/configuration'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { PENSION_PATHS } from '../../../../configuration/constants'
import { selectCurrentUser } from '../../../../store/current/user'
import ContentTitle from '../../ContentTitle'

const consentUrl = getConfig('urls.external.kreditDataConsent')

const PensionsInfoPage = (props: PageProps) => {
  // @ts-expect-error TS(2339) FIXME: Property 'kreditdataHasPensionsInfo' does not exis... Remove this comment to see the full error message
  const { kreditdataHasPensionsInfo } = useObjectSelector(selectCurrentUser)

  return (
    <Paginator.Page {...props}>
      <ContentTitle title="Overvejer du at flytte et pensionsdepot?" />
      <Row>
        <Col>
          <Text as="p" align="center">
            På{' '}
            <a href="https://www.pensionsinfo.dk/" target="_blank" rel="noopener noreferrer">
              PensionsInfo
            </a>{' '}
            kan du få et overblik over alle dine pensioner. Hvis du giver os adgang, kan vi fortælle
            dig hvilke der er mulige at flytte til NORD.
          </Text>
          <Text as="p" align="center">
            Når du klikker nedenfor vil du blive ført over til en ekstern side hvor du med NemID
            eller MitID kan afgive accept til at vi kan hente data fra PensionsInfo.
          </Text>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          {/* @ts-expect-error TS(2322) FIXME: Type '{ children: string; variant: string; icon: n... Remove this comment to see the full error message */}
          <Paginator.Navigation.NextButton variant="link" icon={null}>
            Spring over
          </Paginator.Navigation.NextButton>
          <BaffleButton
            variant="success"
            as={Link}
            href={kreditdataHasPensionsInfo ? PENSION_PATHS.pensionsInfo('ny') : consentUrl}
            filled
          >
            Hent mit pensionsoverblik
          </BaffleButton>
        </Col>
      </Row>
    </Paginator.Page>
  )
}

export default PensionsInfoPage
