import CheckList from '@nord/ui/src/components/CheckList'
import { getConfig } from '@nord/ui/src/configuration'
import { numberToPercent } from '@nord/ui/src/utilities/numberFormatter'
import max from 'lodash/max'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

const returnsUrl = getConfig('urls.website.returns')
const historicalReturns = getConfig('numbers.yearlyHistoricalReturn')
const maxHistoricalReturn = max(Object.values(historicalReturns))

const Advantages = () => (
  <Row>
    <Col xs={12} className="d-flex flex-column align-items-center">
      <p className="text-center mw-100">
        NORD.investments er en digital investeringsløsning til dig, der vil investere pension-,
        frie- og/eller virksomhedsmidler.
      </p>
      <CheckList className="my-3" size="md" textVariant="dark" iconVariant="primary">
        {/* @ts-expect-error TS(2739) FIXME: Type '{ children: (string | Element)[]; }' is miss... Remove this comment to see the full error message */}
        <CheckList.Item>
          <a href={returnsUrl}>Gennemsnitligt årligt afkast de sidste 10 år</a> på{' '}
          {numberToPercent(maxHistoricalReturn)} ved høj risikoprofil
        </CheckList.Item>
        {/* @ts-expect-error TS(2739) FIXME: Type '{ children: string; }' is missing the follow... Remove this comment to see the full error message */}
        <CheckList.Item>Stor spredning, med lavest mulig risiko</CheckList.Item>
        {/* @ts-expect-error TS(2739) FIXME: Type '{ children: string; }' is missing the follow... Remove this comment to see the full error message */}
        <CheckList.Item>Altid lave omkostninger på 0,60 % - 0,80 %</CheckList.Item>
      </CheckList>
    </Col>
  </Row>
)

export default Advantages
