import FullPageTransition from '@nord/ui/src/components/FullPageTransition'
import PageSwitchTransition from '@nord/ui/src/components/PageSwitchTransition'
import React from 'react'
import { useDispatch } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { useLocation } from 'react-router-dom'

import { fetchCurrentUser } from '../../store/current/user'

export interface Props {
  children: React.ReactNode
}

const PageTransition = ({ children, ...props }: Props) => {
  const dispatch = useDispatch()
  let { pathname: pageKey } = useLocation()

  pageKey = pageKey.replace('/ny', '/:id').replace(/\/\d+/, '/:id')

  const handlePageChange = () =>
    // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
    dispatch(fetchCurrentUser({ errorHandling: { ignore: { unauthorized: true } } }))

  return (
    <PageSwitchTransition pageKey={pageKey} afterPageChange={handlePageChange} {...props}>
      <FullPageTransition>{children}</FullPageTransition>
    </PageSwitchTransition>
  )
}

export default PageTransition
