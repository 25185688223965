import anime from 'animejs'
import React, { useState, useEffect } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'

import { defaultTimeoutLimit } from '../../hooks/useWaitForStateChange'

export interface Props {
  timeoutLimit?: number
}

const TimeoutProgressBar = ({ timeoutLimit }: Props) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const animationProps = { progress: 0 }

    anime({
      targets: animationProps,
      progress: 100,
      duration: timeoutLimit,
      round: 1,
      easing: 'easeOutQuad',
      update: () => setProgress(animationProps.progress),
    })

    return () => anime.remove(animationProps)
  }, [timeoutLimit])

  return <ProgressBar now={progress} animated />
}

TimeoutProgressBar.defaultProps = {
  timeoutLimit: defaultTimeoutLimit,
}

export default TimeoutProgressBar
