import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Info from 'react-feather/dist/icons/info'
import { Legend as RechartsLegend } from 'recharts'

import styleConfiguration from '../../configuration/styleConfiguration'
import IconButton from '../IconButton'

const { themeColors } = styleConfiguration

const LEGEND_DATA = [
  {
    name: (
      <>
        <span className="text-dark">Før NORD.investments</span>{' '}
        <OverlayTrigger
          overlay={
            // @ts-expect-error TS(2741) FIXME: Property 'id' is missing in type '{ children: stri... Remove this comment to see the full error message
            <Tooltip>
              NORD.investments startede i 2017, men de indeksfonde, der investeres i har eksisteret
              i mange år.
            </Tooltip>
          }
        >
          <IconButton icon={Info} variant="link" className="p-0" />
        </OverlayTrigger>
      </>
    ),
    stroke: themeColors.secondary,
  },
  {
    name: <span className="text-dark">Siden NORD.investments</span>,
    stroke: themeColors.primary,
  },
]

const PAYLOAD = LEGEND_DATA.map(({ name, stroke }) => ({
  id: name,
  type: 'circle',
  color: stroke,
  value: name,
}))

export interface Props {
  startValue?: number
  payload?: {
    id: string
    type: string
    color: string
    value: string
  }[]
}

const Legend = React.forwardRef<any, Props>(({ ...props }, ref) => (
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  <RechartsLegend iconType="circle" {...props} ref={ref} />
))

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type '(p... Remove this comment to see the full error message
Legend.defaultProps = {
  height: 35,
  payload: PAYLOAD,
  ...RechartsLegend.defaultProps,
  startValue: undefined,
  verticalAlign: 'top',
}

// Makes sure that recharts matches correctly
// @ts-expect-error TS(2339) FIXME: Property 'displayName' does not exist on type '(pr... Remove this comment to see the full error message
Legend.displayName = 'Legend'

export default Legend
