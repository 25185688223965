import BaffleButton from '@nord/ui/src/components/BaffleButton'
import useObjectSelector from '@nord/ui/src/hooks/useObjectSelector'
import React from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import { PATHS } from '../../configuration/constants'
import useFlow from '../../hooks/useFlow'
import { selectCurrentPortfolio } from '../../store/current/portfolioId'

const ReturnNavigation = () => {
  // @ts-expect-error TS(2339) FIXME: Property 'riskScore' does not exist on type 'unkno... Remove this comment to see the full error message
  const { riskScore, id: portfolioId } = useObjectSelector(selectCurrentPortfolio)
  const flow = useFlow()

  return (
    <BaffleButton
      variant="link"
      className="text-light"
      as={Link}
      type={undefined}
      to={{
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        pathname: PATHS[flow].investmentPlanResult(portfolioId),
        search: `?risk_score=${riskScore}`,
      }}
    >
      Tilbage til investeringsplan
    </BaffleButton>
  )
}

export default ReturnNavigation
