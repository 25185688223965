import Text from '@nord/ui/src/components/Text'
import { numberToCurrency } from '@nord/ui/src/utilities/numberFormatter'
import React from 'react'
import Modal from 'react-bootstrap/Modal'

export interface TermsModalProps {
  show: boolean
  referralData?: {
    amount?: number
    depositLimit?: number
    name?: string
    type?: string
  }
  onHide(): void
}

const TermsModal = ({ onHide, referralData, show }: TermsModalProps) => {
  const { name, amount, depositLimit, type } = referralData || {}

  return (
    <Modal className="text-dark" onHide={onHide} show={show} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title className="w-100 text-center" data-cy="modal-title">
          Betingelser
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text as="p">
          Hvis du investerer minimum {numberToCurrency(depositLimit)} i en måned med henvisning fra{' '}
          <strong>{name}</strong>, vil du modtage en belønning på{' '}
          <strong>{numberToCurrency(amount)}</strong> fra NORD.investments på din investeringskonto
          forudsat, at du opfylder følgende betingelser:
        </Text>
        <ul>
          <li>
            Du må ikke allerede være oprettet hos NORD.investments i dag eller have været kunde hos
            NORD.investments inden for de seneste 12 måneder
          </li>
          <li>
            Du skal kunne godkendes af NORD.investments som kunde og erklæres egnet til at investere
            samt have oprettet konto og depot hos Saxo Bank
          </li>
        </ul>
        {type === 'MemberReferral' && (
          <Text as="p">
            Når betingelserne er opfyldt, vil du og <strong>{name}</strong> hver modtage{' '}
            <strong>{numberToCurrency(amount)}</strong>
          </Text>
        )}
        <Text as="p">
          {type === 'MemberReferral' ? (
            <>
              De <strong>{numberToCurrency(amount)}</strong> vil blive sat ind på din
              investeringskonto i kalendermåneden efter, at du har været investeret i 1 måned. Husk
              at du altid kan trække dine penge ud, men du og din ven vil ikke få jeres{' '}
              <strong>{numberToCurrency(amount)}</strong>, hvis ikke du har haft de{' '}
              {numberToCurrency(depositLimit)} investeret i 1 sammenhængende måned.
            </>
          ) : (
            <>
              De <strong>{numberToCurrency(amount)}</strong> vil blive sat ind på din konto i
              kalendermåneden efter du har været investeret i en måned. Husk at du altid kan trække
              dine penge ud, men du vil dog ikke få de <strong>{numberToCurrency(amount)}</strong>,
              hvis du ikke har haft de {numberToCurrency(depositLimit)} investeret i en måned.
            </>
          )}
        </Text>
        <Text as="p">
          Du skal være opmærksom på, at belønningen kan påvirke din skattemæssige indkomst, og at du
          selv er ansvarlig for at indberette belønningen.{' '}
          {name === 'Visma LogBuy' &&
            `Bemærk derudover, at NORD.investments betaler ${name} ${numberToCurrency(
              400,
            )} per henvisning.`}
        </Text>
        <Text as="p">
          {type === 'MemberReferral' ? (
            'Vi forbeholder os retten til at tilbagekalde tilbuddet om belønning.'
          ) : (
            <>
              I øvrigt er belønningen omfattet af de til enhver tid gældende vilkår, der er fastsat
              af NORD.investments Fondsmæglerselskab A/S.
            </>
          )}
        </Text>
      </Modal.Body>
    </Modal>
  )
}

TermsModal.defaultProps = {
  referralData: {},
}

export default TermsModal
