import BaffleButton from '@nord/ui/src/components/BaffleButton'
import { selectCurrentPortfolioId } from '@nord/ui/src/store/current/portfolioId'
import React from 'react'
import { useSelector } from 'react-redux'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from 'react-router-dom'

import { PATHS } from '../../../../../../../configuration/constants'
import useFlow from '../../../../../../../hooks/useFlow'

const StartInvestmentPlan = ({ ...otherProps }) => {
  const flow = useFlow()
  const portfolioId = useSelector(selectCurrentPortfolioId)

  return (
    <BaffleButton
      variant="success"
      as={Link}
      type={undefined}
      // @ts-expect-error TS(2322) FIXME: Type '{ children: string; variant: string; as: any... Remove this comment to see the full error message
      to={flow ? PATHS[flow].investmentPlan(portfolioId) : PATHS.shared.root()}
      filled
      {...otherProps}
    >
      Få din personlige investeringsplan
    </BaffleButton>
  )
}

export default StartInvestmentPlan
