import { numberToPercent } from '@nord/ui/src/utilities/numberFormatter'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Section from './Section'

const ResponsibleInvestment = ({ ...otherProps }) => (
  // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; title: string; id: stri... Remove this comment to see the full error message
  <Section title="Ansvarlig investering" id="ansvarlig-investering" {...otherProps}>
    <Row>
      <Col xs={12} md={8}>
        <p className="text-center text-lg-left">
          Med en ansvarlig portefølje, investerer du i virksomheder, der prioriterer miljø, etik og
          menneskerettigheder. Samtidigt fravælges virksomheder der ikke tager afstand til
          kontroversielle emner som bl.a. atomkraft, våben og børnearbejde.
        </p>
      </Col>
      <Col>
        <ul>
          <li>
            Udleder {numberToPercent(0.5, { fractionDigits: 0 })} mindre vand end en
            standardportefølje
          </li>
          <li>{numberToPercent(0.32, { fractionDigits: 0 })} mindre CO2</li>
          <li>{numberToPercent(0.5, { fractionDigits: 0 })} mindre olie, kul og gas</li>
        </ul>
      </Col>
    </Row>
  </Section>
)

export default ResponsibleInvestment
